interface PickupTruckSvgProps {
  size?: number
  color?: string
}

const PickupTruckSvg = ({ size = 32, color = '#666' }: PickupTruckSvgProps) => {
  const second = false
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      xmlns='http://www.w3.org/2000/svg'
    >
      {second ? (
        <g clipPath='url(#clip0_178_17)'>
          <path d='M0.587016 17.4546C0.587016 17.4546 0.360247 19.0011 0.700406 19.2391L2.3634 19.7942C2.3634 19.1457 2.46123 18.5129 2.69173 17.9701M0.587016 17.4546L0.964973 16.9787C0.964973 16.9787 0.961604 16.802 0.96995 16.5425C0.981899 16.1709 1.01786 15.6297 1.1221 15.1942C1.18894 14.915 1.28386 14.6793 1.41851 14.5597C1.90985 14.1235 7.84372 13.7666 7.84372 13.7666L12.4925 10.7528L18.5416 10.5943C19.2219 11.8633 19.2219 13.846 19.2219 13.846M0.587016 17.4546C0.750797 17.6 1.16151 17.8987 1.49411 17.9304C1.82671 17.9621 2.4311 17.9701 2.69173 17.9701M19.2219 13.846L30.8492 13.8002V14.633M19.2219 13.846C19.2597 15.2007 19.3353 18.8823 18.8818 19.4375M30.8492 17.6468H31.4917C31.5547 18.0962 31.5144 18.9951 30.8492 18.9951C30.6049 18.9951 30.1731 19.0093 29.6776 19.03M30.8492 17.6468H30.0555C29.9547 17.66 29.7532 17.734 29.7532 17.9244C29.7532 18.1147 29.7028 18.7408 29.6776 19.03M30.8492 17.6468V16.4968M29.6776 19.03C28.8238 19.0656 27.9509 19.1202 27.353 19.1537C27.353 17.845 26.5783 16.2192 24.4618 16.2192C22.6135 16.2192 21.5757 17.6663 21.5757 19.4198L18.8818 19.4375M2.69173 17.9701C3.09549 17.0192 3.90632 16.3442 5.31143 16.3442C7.51868 16.3442 8.29726 18.2873 8.29726 19.5563L18.8818 19.4375M30.8492 14.633H29.413V15.545L30.3201 16.4968H30.8492M30.8492 14.633V16.4968M10.5 14L17.3453 13.8002' />
          <circle cx='5.34313' cy='19.4727' r='1.875' />
          <circle cx='24.4443' cy='19.4727' r='1.875' />
        </g>
      ) : (
        <g clipPath='url(#clip0_178_27)'>
          <path d='M2.3634 19.7942L0.700406 19.2391C0.360247 19.0011 0.587016 17.4546 0.587016 17.4546L0.964973 16.9787C0.964973 16.9787 0.961604 16.802 0.96995 16.5425C0.981899 16.1709 1.01786 15.6297 1.1221 15.1942C1.18894 14.915 1.28386 14.6793 1.41851 14.5597C1.90985 14.1235 7.84372 13.7666 7.84372 13.7666L12.4925 10.7528L18.5416 10.5943C19.2219 11.8633 19.2219 13.846 19.2219 13.846M19.2219 13.846L30.8492 13.8002V14.633V16.4968V17.6468H31.4917C31.5547 18.0962 31.5144 18.9951 30.8492 18.9951C30.6049 18.9951 30.1731 19.0093 29.6776 19.03C28.8238 19.0656 27.9509 19.1202 27.353 19.1537M19.2219 13.846C19.2597 15.2007 19.3353 18.8823 18.8818 19.4375M21.5757 19.4198L18.8818 19.4375M8.29726 19.5563L18.8818 19.4375M11.5 14L16.8099 13.846' />
          <circle cx='5.5' cy='19.3' r='2.5' />
          <circle cx='24.5' cy='19.3' r='2.5' />
        </g>
      )}
    </svg>
  )
}

export default PickupTruckSvg
