import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ButtonGroup } from '@atlaskit/button'
import Button, { IconButton } from '@atlaskit/button/new'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import { token } from '@atlaskit/tokens'

import MoreIcon from '@atlaskit/icon/glyph/more'

import DeleteJobModal, { JobQueryJobType } from './DeleteJobModal'

const JobPageActions = ({ job }: { job: JobQueryJobType }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const navigate = useNavigate()
  if (!job) return null

  return (
    <>
      <ButtonGroup label='button group'>
        <Button onClick={() => navigate('create-estimate', { state: { title: job.title } })}>Create Estimate</Button>

        <DropdownMenu
          placement='bottom-end'
          trigger={({ triggerRef, ...props }) => <IconButton {...props} icon={MoreIcon} label='more' ref={triggerRef} />}
        >
          <DropdownItemGroup>
            <DropdownItem onClick={() => setShowDeleteModal(true)}>
              <span style={{ color: token('color.text.danger') }}>Delete Job</span>
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      </ButtonGroup>

      <DeleteJobModal job={job} onClose={() => setShowDeleteModal(false)} isOpen={showDeleteModal} />
    </>
  )
}

export default JobPageActions
