import { useAtomValue } from 'jotai'

import { IconButton } from '@atlaskit/button/new'
import DownloadIcon from '@atlaskit/icon/glyph/download'

import { dbDispatchDatesAtom } from '../atoms'
import { CalendarWorkOrderType } from '../types'

const DownloadCalendarButton = () => {
  const dispatchDates = useAtomValue(dbDispatchDatesAtom)
  const calendarItems = Object.values(dispatchDates)
    .flat()
    .map(item => {
      const { workOrder, dateString } = item
      const { id, name, customerName, projectSiteAddress, jobTitle, isConfirmed, isWorkCompleted, estimateTitle } = workOrder ?? {}
      return {
        date: dateString,
        customerName: customerName,
        jobTitle: jobTitle,
        projectSiteAddress: projectSiteAddress,
        estimateTitle: estimateTitle,
        workOrderId: id,
        workOrderName: name,
        workOrderIsConfirmed: isConfirmed,
        workOrderIsWorkCompleted: isWorkCompleted,
      }
    })

  const handleDownload = () => {
    // @ts-expect-error don't really care about this one
    const csvContent = generateCSV(calendarItems)
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', 'calendar_items.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return <IconButton icon={DownloadIcon} label='Download Calendar' isTooltipDisabled={false} onClick={handleDownload} />
}

const generateCSV = (items: CalendarWorkOrderType[]) => {
  const headers = Object.keys(items[0]).join(',')
  const rows = items.map(item => Object.values(item).join(','))
  return [headers, ...rows].join('\n')
}

export default DownloadCalendarButton
