import { useMemo } from 'react'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import { CELL_PADDING_HORIZONTAL, CELL_PADDING_VERTICAL } from '../editorConstants'

interface NumberCellProps {
  value: number | string | null | undefined
  decimalPlaces?: number
  prefix?: string
  suffix?: string
  readOnly?: boolean
  padding?: string
}

const formatNumber = (num: number, decimalPlaces: number): string => {
  const parts = num.toFixed(decimalPlaces).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

const NumberCell = ({ value, decimalPlaces = 2, prefix = '', suffix = '', readOnly = false, padding }: NumberCellProps) => {
  const { valueToUse, shouldDim } = useMemo(() => {
    let valueToUse = value
    if (value === null || value === undefined || value === '') valueToUse = '-'
    else if (typeof value === 'string') valueToUse = parseFloat(value).toFixed(decimalPlaces)
    else if (typeof value === 'number') valueToUse = value.toFixed(decimalPlaces)
    else valueToUse = 'INVALID'

    let shouldDim = false
    if (valueToUse === '-') shouldDim = true
    else if (typeof valueToUse === 'string' && valueToUse !== 'INVALID' && valueToUse.includes('0')) {
      const parts = valueToUse.split('.')
      const leftOfDecimal = parts[0]
      const rightOfDecimal = parts[1]
      if (parseInt(leftOfDecimal) === 0 && parseInt(rightOfDecimal) === 0) shouldDim = true
    }

    if (typeof valueToUse === 'number' && valueToUse >= 1000) valueToUse = formatNumber(valueToUse, decimalPlaces)
    else if (typeof valueToUse === 'string' && !isNaN(parseFloat(valueToUse)) && parseFloat(valueToUse) >= 1000)
      valueToUse = formatNumber(parseFloat(valueToUse), decimalPlaces)

    return { valueToUse, shouldDim }
  }, [value, decimalPlaces])

  const noWrapStyles = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        fontFamily: MONOSPACE_FONT_FAMILY,
        opacity: readOnly ? 0.9 : 1,
        padding: padding ?? `${CELL_PADDING_VERTICAL}px ${CELL_PADDING_HORIZONTAL}px`,
        ...noWrapStyles,
      }}
    >
      <span style={{ marginRight: 4, paddingLeft: readOnly ? undefined : 6, color: token('color.text.disabled') }}>{prefix}</span>

      <span>
        <span style={{ color: shouldDim ? token('color.text.disabled') : 'inherit', ...noWrapStyles }}>{valueToUse}</span>

        {!!suffix && (
          <span style={{ paddingLeft: 6, fontWeight: '500', color: token('color.text.disabled'), paddingRight: readOnly ? undefined : 6 }}>
            {suffix}
          </span>
        )}
      </span>
    </div>
  )
}

export default NumberCell
