import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

import { databaseWorkItemsAtom } from '../editorAtoms'
import useEstimateSortOrder from './useEstimateSortOrder'

const useSortedWorkItems = () => {
  const { sortOrder } = useEstimateSortOrder()
  const workItems = useAtomValue(databaseWorkItemsAtom)

  const sortedWorkItems = useMemo(() => {
    const keys = Object.keys(workItems)
    if (!sortOrder || !workItems || keys.length !== sortOrder.length) return keys.map(id => workItems[id])
    return sortOrder.map(id => workItems[id])
  }, [workItems, sortOrder])

  return sortedWorkItems
}

export default useSortedWorkItems
