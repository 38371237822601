import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

type AppRightPanelAttributeProps = {
  label?: string
  children?: React.ReactNode | string
  mono?: boolean
  to?: string
  toolTip?: string
  noWrap?: boolean
  dim?: boolean
  openNewTab?: boolean
}

const AppRightPanelAttribute = ({
  label = 'Label',
  children = 'Value',
  mono = false,
  openNewTab = false,
  to,
  toolTip,
  noWrap,
  dim,
}: AppRightPanelAttributeProps) => {
  return (
    <AttributeRow>
      <AttributeLabelOuter>
        <AttributeLabelInner>
          <AttributeLabel>{label}</AttributeLabel>
        </AttributeLabelInner>
      </AttributeLabelOuter>

      <AttributeValueRow>
        <Tooltip content={toolTip ?? children}>
          {tooltipProps => (
            <AttributeValueText $mono={mono} $noWrap={noWrap} $dim={dim} {...tooltipProps}>
              {to ? (
                <Link appearance='subtle' href={to} target={openNewTab ? '_blank' : undefined}>
                  <span>{children}</span>
                </Link>
              ) : (
                children
              )}
            </AttributeValueText>
          )}
        </Tooltip>
      </AttributeValueRow>
    </AttributeRow>
  )
}

export default AppRightPanelAttribute

const AttributeRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: stretch;
  justify-content: flex-start;
`
const AttributeLabelOuter = styled.div`
  width: 120px;
  padding-right: 8px;
  text-align: left;
  flex-shrink: 0;
`
const AttributeLabelInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 36px;
`
const AttributeLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  color: ${token('color.text.subtle')};
  max-width: 140px;
  overflow: hidden;
  padding-top: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`
const AttributeValueRow = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
  justify-content: start;
  align-items: stretch;
  flex-direction: row;
  display: flex;
`
type AttributeValueProps = {
  $mono: boolean
  $noWrap?: boolean
  $dim?: boolean
}
const AttributeValueText = styled.div<AttributeValueProps>`
  font-size: 14px;
  flex: 1 1 auto;
  ${({ $dim }) => ($dim === true ? `color: ${token('color.text.disabled')};` : '')}
  ${({ $noWrap }) =>
    $noWrap === true
      ? `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;`
      : ''}
  align-self: center;
  ${({ $mono }) => ($mono === true ? `font-family: ${MONOSPACE_FONT_FAMILY}; font-weight: 500;` : '')}
`
