import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'
import type { TableProps } from 'antd'
import { ConfigProvider, Table } from 'antd'
import { format } from 'date-fns'
import { useNavigate, useParams } from 'react-router-dom'

import { DatePicker } from '@atlaskit/datetime-picker'
import Link from '@atlaskit/link'

import { graphql } from '@/gql'
import { GetPayrollWeekDispatchesQuery } from '@/gql/graphql'
import useAntdTableTheme from '@/utils/useAntdTableTheme'
import useGraphQLClient from '@/utils/useAuthRequest'
import { formatCurrencyString, onlyMondaysFilter } from '@/utils/utilities'

import AppPage from '@/components/AppPage'
import TextCell from '../../jobs/EstimateEditor/components/TextCell'

const WeeklyDispatchPayout = () => {
  const params = useParams()
  const dateString = params?.dateString ?? Temporal.Now.plainDateISO().toString()
  const graphQLClient = useGraphQLClient()
  const { data, isPending } = useQuery({
    queryKey: [GET_PAYROLL_WEEK_DISPATCHES_QUERY_KEY, dateString],
    queryFn: async () => {
      const response = await graphQLClient.request(GET_PAYROLL_WEEK_DISPATCHES, { forWeek: dateString })
      return {
        ...response,
        dispatches: [...response.dispatches].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
      }
    },
  })
  const navigate = useNavigate()

  const actions = (
    <DatePicker
      spacing='compact'
      innerProps={{ style: { width: 110 } }}
      onChange={handleDateChange}
      value={dateString}
      disabledDateFilter={onlyMondaysFilter}
      formatDisplayLabel={dateString => {
        const dateObj = new Date(dateString + 'T00:00:00')
        const formattedDate = format(dateObj, 'MMM do')
        return formattedDate
      }}
    />
  )

  const theme = useAntdTableTheme()

  return (
    <AppPage
      header='Weekly Dispatch Payout'
      isRightPanelOpen={false}
      rightPanelComponent={null}
      // rightPanelWidth={0}
      // bottomBar={null}
      actions={actions}
      isLoading={isPending}
      disableScroll={true}
    >
      <title>Weekly Dispatch Payout - Thermal Shop Admin</title>
      <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: 0, bottom: 0, left: 32, right: 32 }}>
        <ConfigProvider theme={theme}>
          <Table
            rowKey='id'
            columns={columns}
            dataSource={data?.dispatches ?? []}
            loading={isPending}
            pagination={false}
            size='small'
            scroll={{ y: 'calc(100vh - 200px)' }}
          />
        </ConfigProvider>
      </div>
    </AppPage>
  )
  function handleDateChange(date: string) {
    navigate(`/recon/weekly-payout/${date}`)
  }
}

export default WeeklyDispatchPayout
type Dispatch = GetPayrollWeekDispatchesQuery['dispatches'][number]
type InstallerAssignment = Dispatch['installerAssignments'][number]

const columns: TableProps<Dispatch>['columns'] = [
  {
    key: 'date',
    dataIndex: 'date',
    title: 'Date',
    align: 'left',
  },
  {
    key: 'workOrder',
    title: 'Work Order',
    // width: 240,
    fixed: 'left',
    render: ({ workOrder, workOrderId, date }) => (
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%', boxSizing: 'border-box' }}>
        <Link href={`/recon/work-order/${workOrderId}/${date}`}>
          <span>
            {workOrderId}-{workOrder?.name}
          </span>
        </Link>
      </div>
    ),
  },
  {
    key: 'projectSite',
    title: 'Project Site',
    align: 'left',
    render: ({ workOrder }) => <TextCell value={workOrder?.projectSiteAddress || '-'} padding='0 0px' />,
  },
  {
    key: 'customer',
    title: 'Customer',
    align: 'left',
    render: ({ workOrder }) => workOrder?.customerName || '-',
  },
  {
    key: 'laborPayoutBase',
    dataIndex: 'laborPayoutBase',
    title: 'Labor Payout',
    align: 'right',
    render: text => formatCurrencyString(text),
  },
  {
    key: 'installers',
    title: 'Installers',
    render: ({ installerAssignments }) => (
      <div>
        {((installerAssignments ?? []) as InstallerAssignment[])
          .sort((a, b) => a.installer?.fullName.localeCompare(b.installer?.fullName))
          .map(assignment => (
            <div key={assignment.id} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: 11 }}>
              {assignment.installer?.fullName}
              {assignment.isHourly && ' (H)'}
            </div>
          ))}
      </div>
    ),
  },
  {
    key: 'marginPercent',
    title: 'Margin %',
    align: 'right',
    render: ({ workOrder }) => (
      <TextCell value={workOrder?.marginPercent ? workOrder?.marginPercent + '%' : '-'} align='right' monospace padding='0 0px' />
    ),
  },
  {
    key: 'totalPriceFinal',
    title: 'Total Price',
    align: 'right',
    render: ({ workOrder }) => (
      <TextCell value={formatCurrencyString(workOrder?.laborCostTotal ?? 0)} align='right' monospace padding='0 0px' />
    ),
  },
]

const GET_PAYROLL_WEEK_DISPATCHES_QUERY_KEY = 'GetPayrollWeekDispatches'
const GET_PAYROLL_WEEK_DISPATCHES = graphql(/* GraphQL */ `
  query GetPayrollWeekDispatches($forWeek: Date!) {
    dispatches(filters: { forWeek: $forWeek }) {
      id
      date
      laborPayoutBase
      laborPayoutAddon
      previouslyPaidBase
      previouslyPaidAddon
      workOrderId
      workOrder {
        id
        name
        tripName
        tripId
        laborCostTotal
        totalAddLaborCost
        estimateTitle
        jobId
        jobTitle
        projectSiteAddress
        customerName
        marginPercent
      }
      installerAssignments {
        id
        isHourly
        installerId
        installer {
          id
          fullName
        }
      }
    }
  }
`)
