import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { CalendarWorkOrderType } from './types'

export const workOrderSelectedAtom = atom<CalendarWorkOrderType | null>(null)
export const workOrderPickedUpAtom = atom<CalendarWorkOrderType | null>(null)

export const dbDispatchDatesAtom = atom<Record<string, CalendarWorkOrderType[]>>({})

export const dateStringHoveredAtom = atom<string | null>(null)
export const dateStringPendingAddAtom = atom<string | null>(null)
export const dateStringSelectedAtom = atom<string | null>(null)
export const unscheduledCountAtom = atom<number | null>(null)

export const isWeeksExpandedAtom = atomWithStorage<boolean>('isWeeksExpanded', false, undefined, { getOnInit: true })
export const isUnscheduledVisibleAtom = atomWithStorage<boolean>('isUnscheduledVisible', true, undefined, { getOnInit: true })
