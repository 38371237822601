import { useCallback, useState } from 'react'

import Button from '@atlaskit/button/new'
import PrinterIcon from '@atlaskit/icon/core/printer'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import PrintDayLoadSheetsForm from './PrintDayLoadSheetsForm'

interface PrintDayLoadSheetsModalProps {
  dateString: string | null
  vehicleId?: string | null
}

export default function PrintDayLoadSheetsModal({ dateString, vehicleId }: PrintDayLoadSheetsModalProps) {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Button onClick={() => setIsOpen(true)} iconBefore={PrinterIcon}>
        Load Sheets
      </Button>

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <PrintDayLoadSheetsForm closeModal={closeModal} dateString={dateString} vehicleId={vehicleId} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}
