import { useSetAtom } from 'jotai'
import Select, { Theme } from 'react-select'

import useTripsQuery from '@/utils/queryHooks/useTripsQuery'

import { GetTripsQuery } from '@/gql/graphql'

import useSelectColors from '../hooks/useSelectColors'
import useWorkItemAttribute from '../hooks/useWorkItemAttribute'

import { workItemEditsQueueAtom, workItemsOptimisticAttributesAtom } from '../editorAtoms'
import { UNIVERSAL_EDITOR_SELECT_PROPS } from '../editorConstants'

type Trip = GetTripsQuery['trips'][0]
type TripOption = {
  label: string
  value: string
  trip: Trip
}

interface TripCellProps {
  workItemId: string | null
}

const TripCell = ({ workItemId }: TripCellProps) => {
  const setWorkItemEditsQueue = useSetAtom(workItemEditsQueueAtom)
  const setOptimisticAttributes = useSetAtom(workItemsOptimisticAttributesAtom)

  const { trips } = useTripsQuery()
  const options = trips.map((trip: Trip) => ({ label: trip.name, value: trip.id, trip: trip }))

  const trip = useWorkItemAttribute('trip', workItemId ?? 'null')

  const handleChange = (option: TripOption | null) => {
    setOptimisticAttributes(prev => {
      const key = workItemId ?? 'null'
      const existingWorkItem = prev?.[key] ?? {}
      console.log('setOptimisticAttributes: ', { key, option, existingWorkItem })
      return {
        ...prev,
        [key]: {
          ...existingWorkItem,
          trip: option?.trip,
          tripId: option?.value ? Number(option.value) : null,
        },
      }
    })
    // @ts-expect-error - JSON Value type error
    if (workItemId !== 'NEW') setWorkItemEditsQueue(prev => [...prev, { workItemId, attribute: 'tripId', value: option?.value ?? '' }])
  }
  const colors = useSelectColors()

  return (
    <div style={{ width: '100%', minWidth: 60 }}>
      <Select
        options={options}
        components={{ IndicatorSeparator: null, IndicatorContainer: null }}
        value={!trip?.id ? null : { label: trip.name, value: trip.id }}
        placeholder='Select Trip'
        inputId='trip-selector'
        classNamePrefix='trip-selector'
        onChange={handleChange}
        theme={(theme: Theme) => ({
          ...theme,
          borderRadius: 0,
          colors,
        })}
        {...UNIVERSAL_EDITOR_SELECT_PROPS}
      />
    </div>
  )
}

export default TripCell
