import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

import { dateStringPendingAddAtom, workOrderPickedUpAtom, workOrderSelectedAtom } from '../atoms'

interface WorkOrderState {
  workOrderId: string
  workOrderDateString?: string | null
  dayCellDateString?: string | null
}

const useWorkOrderState = ({ workOrderId, workOrderDateString, dayCellDateString }: WorkOrderState) => {
  const workOrderSelected = useAtomValue(workOrderSelectedAtom)
  const isSelected = workOrderSelected?.workOrderId === workOrderId

  const workOrderPickedUp = useAtomValue(workOrderPickedUpAtom)
  const dateStringPendingAdd = useAtomValue(dateStringPendingAddAtom)

  const isPickedUp = workOrderPickedUp?.workOrderId === workOrderId
  const isPending = dateStringPendingAdd === dayCellDateString && isPickedUp
  const isPlaceholder = workOrderDateString && dayCellDateString && workOrderDateString !== dayCellDateString

  return useMemo(
    () => ({
      isPending,
      isSelected,
      isPlaceholder,
    }),
    [isPending, isSelected, isPlaceholder]
  )
}

export default useWorkOrderState
