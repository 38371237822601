import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'

import { graphql } from '@/gql'
import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useGraphQLClient from '@/utils/useAuthRequest'

import AppPage from '@/components/AppPage'
import NumberCell from '@/pages/jobs/EstimateEditor/components/NumberCell'
import SelectedDispatch from './components/SelectedDispatch'

const CELL_PAD = '4px 8px'

const DispatchPayoutDetails = () => {
  const { workOrderId, dateString } = useParams()
  const graphQLClient = useGraphQLClient()

  const { data, isPending } = useQuery({
    queryKey: [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY, workOrderId],
    queryFn: async () => {
      if (!workOrderId) throw new Error('Work Order ID is required')
      const response = await graphQLClient.request(GET_DISPATCH_PAYOUT_DETAILS, {
        workOrderId,
      })
      return response
    },
    enabled: !!workOrderId,
  })

  const workOrder = data?.workOrder
  const dispatches = (workOrder?.dispatches ?? []).sort((a, b) => a.date.localeCompare(b.date))

  const breadcrumbs = useMemo(() => {
    return [{ navigateTo: '/recon/weekly-payout', label: 'Weekly Payout' }]
  }, [])
  const title = `${workOrder?.id}-${workOrder?.name}`
  const projectedTotal = workOrder?.laborCostTotal ?? 0
  const projectedAddLabor = workOrder?.totalAddLaborCost ?? 0
  const projectedBaseLabor = projectedTotal - projectedAddLabor
  const totalPaidBase = Number(workOrder?.totalPaidBase ?? 0)
  const totalPaidAddon = Number(workOrder?.totalPaidAddon ?? 0)
  const totalPaid = totalPaidBase + totalPaidAddon

  const selectedDispatch = dispatches.find(dispatch => dispatch.date === dateString)
  const remainingBase = projectedBaseLabor - totalPaidBase
  const remainingAddon = projectedAddLabor - totalPaidAddon
  const remainingTotal = remainingBase + remainingAddon

  return (
    <AppPage
      header={`Dispatch Payout - WO #${title}`}
      breadcrumbs={breadcrumbs}
      isRightPanelOpen={false}
      rightPanelComponent={null}
      isLoading={isPending}
    >
      <title>Dispatch Payout Details - Thermal Shop Admin</title>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          maxWidth: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            overflowX: 'auto',
          }}
        >
          <StyledTable>
            <thead>
              <tr>
                <th className='sticky-column' />

                <th className=''>WO Projected</th>

                <th className=''>WO Logged</th>

                {dispatches.map(dispatch => {
                  const isCurrentWeek = dateString === dispatch.date
                  const formattedDate = format(new Date(dispatch.date), 'MM/dd')
                  return (
                    <th key={dispatch.id} className={isCurrentWeek ? 'current-week' : ''}>
                      {isCurrentWeek ? (
                        formattedDate
                      ) : (
                        <Link href={`/recon/work-order/${workOrderId}/${dispatch.date}`} appearance='subtle'>
                          {formattedDate}
                        </Link>
                      )}
                    </th>
                  )
                })}
                <th className=''>WO Remaining</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className='row-header sticky-column'>Hours Worked</td>
                <td className='' />
                <td className='no-padding '>
                  <NumberCell padding={CELL_PAD} readOnly value={workOrder?.totalHoursWorked ?? 0} />
                </td>

                {dispatches.map(dispatch => (
                  <td key={dispatch.id} className={'no-padding' + (dateString === dispatch.date ? ' current-week' : '')}>
                    <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalHoursWorked ?? 0} />
                  </td>
                ))}
                <td className='no-padding'></td>
              </tr>

              <tr>
                <td className='row-header sticky-column'>Base Labor</td>

                <td className=''>
                  <NumberCell padding={CELL_PAD} readOnly value={projectedBaseLabor} prefix='$' />
                </td>

                <td className=''>
                  <NumberCell padding={CELL_PAD} readOnly value={totalPaidBase} prefix='$' />
                </td>

                {dispatches.map(dispatch => (
                  <td key={dispatch.id} className={'no-padding' + (dateString === dispatch.date ? ' current-week' : '')}>
                    <NumberCell padding={CELL_PAD} readOnly value={dispatch.laborPayoutBase ?? 0} prefix='$' />
                  </td>
                ))}

                <td className='no-padding'>
                  <NumberCell padding={CELL_PAD} readOnly value={remainingBase} prefix='$' />
                </td>
              </tr>

              <tr>
                <td className='row-header sticky-column'>Add Labor</td>

                <td className=''>
                  <NumberCell padding={CELL_PAD} readOnly value={projectedAddLabor} prefix='$' />
                </td>

                <td className=''>
                  <NumberCell padding={CELL_PAD} readOnly value={totalPaidAddon} prefix='$' />
                </td>

                {dispatches.map(dispatch => (
                  <td key={dispatch.id} className={'no-padding' + (dateString === dispatch.date ? ' current-week' : '')}>
                    <NumberCell padding={CELL_PAD} readOnly value={dispatch.laborPayoutAddon ?? 0} prefix='$' />
                  </td>
                ))}

                <td className='no-padding'>
                  <NumberCell padding={CELL_PAD} readOnly value={remainingAddon} prefix='$' />
                </td>
              </tr>

              <tr>
                <td className='row-header sticky-column'>Total Payout</td>

                <td className=''>
                  <NumberCell padding={CELL_PAD} readOnly value={projectedTotal} prefix='$' />
                </td>

                <td className=''>
                  <NumberCell padding={CELL_PAD} readOnly value={totalPaid} prefix='$' />
                </td>

                {dispatches.map(dispatch => {
                  const base = Number(dispatch.laborPayoutBase ?? 0)
                  const addon = Number(dispatch.laborPayoutAddon ?? 0)
                  const total = base + addon
                  return (
                    <td key={dispatch.id} className={'no-padding' + (dateString === dispatch.date ? ' current-week' : '')}>
                      <NumberCell padding={CELL_PAD} readOnly value={total} prefix='$' />
                    </td>
                  )
                })}

                <td className='no-padding'>
                  <NumberCell padding={CELL_PAD} readOnly value={remainingTotal} prefix='$' />
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </div>
        {!!selectedDispatch && !!workOrder && <SelectedDispatch dispatch={selectedDispatch} workOrder={workOrder} />}
      </div>
    </AppPage>
  )
}

const StyledTable = styled.table`
  td {
    font-family: ${MONOSPACE_FONT_FAMILY};
    font-weight: 400;

    &.no-padding {
      padding: 0;
    }
  }
  th,
  td {
    text-align: right;
  }
  th.sticky-column,
  td.sticky-column {
    position: sticky;
    background: white;
    z-index: 1;
  }
  th.sticky-column {
    top: 0;
  }
  td.sticky-column {
    left: 0;
  }
  tr:nth-child(even) {
    background-color: ${token('color.background.neutral')};
    .current-week {
      background: ${token('color.background.selected.hovered')};
    }
  }
  .zero {
    color: ${token('color.text.disabled')};
  }
  .row-header {
    font-family: ${token('font.family.body')};
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 8px;
  }
  .current-week {
    background: ${token('color.background.selected')};
  }
`

export default DispatchPayoutDetails

const GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY = 'GetDispatchPayoutDetails'
const GET_DISPATCH_PAYOUT_DETAILS = graphql(/* GraphQL */ `
  query GetDispatchPayoutDetails($workOrderId: ID!) {
    workOrder(pk: $workOrderId) {
      id
      name
      tripName
      tripId
      laborCostTotal
      totalAddLaborCost
      totalPaidBase
      totalPaidAddon
      estimateTitle
      jobId
      jobTitle
      projectSiteAddress
      customerName
      marginPercent
      totalHoursWorked
      dispatches {
        id
        date
        laborPayoutBase
        laborPayoutAddon
        previouslyPaidBase
        previouslyPaidAddon
        totalHoursWorked
        workOrderId
        installerAssignments {
          id
          isHourly
          payoutAmount
          hoursWorked
          installerId
          installer {
            id
            fullName
          }
        }
      }
    }
  }
`)
