import { ConfigProvider, Table, TableColumnsType } from 'antd'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { GetCalculatedLoadSheetQuery } from '@/gql/graphql'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useAntdTableTheme from '@/utils/useAntdTableTheme'

interface LoadSheetTableProps {
  data: LoadSheetItem[]
  error: Error | null
  isPending: boolean
}

const LoadSheetTable = ({ error, isPending, data }: LoadSheetTableProps) => {
  const theme = useAntdTableTheme()

  return (
    <StyledLoadSheetTable initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ height: 0 }} layout layoutScroll>
      {error ? <div>Error: {error.message}</div> : null}
      {isPending ? (
        <div style={{ padding: '12px' }}>Loading...</div>
      ) : data && data.length === 0 ? (
        <div style={{ padding: '12px' }}>No Work Items Found</div>
      ) : (
        <ConfigProvider theme={theme}>
          <Table dataSource={data} columns={columns} size='small' pagination={false} loading={isPending} rowKey={row => row.itemCode} />
        </ConfigProvider>
      )}
    </StyledLoadSheetTable>
  )
}

export default LoadSheetTable

export type LoadSheetItem = GetCalculatedLoadSheetQuery['calculatedLoadSheetItems'][number]
const columns: TableColumnsType<LoadSheetItem> = [
  {
    title: 'Material',
    dataIndex: 'materialName',
  },
  {
    title: 'Quantity',
    dataIndex: 'unitQuantity',
    align: 'right',
    render: (value: number) => <span style={{ fontFamily: MONOSPACE_FONT_FAMILY }}>{value}</span>,
  },
  {
    title: 'UoM',
    dataIndex: 'materialUnitOfMeasure',
  },
  // {
  //   title: 'Item Code',
  //   dataIndex: 'itemCode',
  // },
  {
    title: 'Called For',
    dataIndex: 'quantityCalledFor',
    align: 'right',
    render: (value: number) => <span style={{ fontFamily: MONOSPACE_FONT_FAMILY }}>{value}</span>,
  },
  {
    title: 'Container',
    dataIndex: 'materialContainerLabel',
  },
]

const StyledLoadSheetTable = styled(motion.div)`
  h4 {
    margin-bottom: 10px;
  }
  .ant-table {
    background: transparent;
  }
  .ant-table-container {
    margin: 12px;
    background: ${token('elevation.surface')};
    border-radius: 6px;
  }
  overflow: hidden;
`
