import { Row } from '@tanstack/react-table'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline'
import LockIcon from '@atlaskit/icon/glyph/lock'
import UnlockIcon from '@atlaskit/icon/glyph/unlock'

import Tooltip from '@atlaskit/tooltip'
import { CELL_PADDING_HORIZONTAL, COLUMN_WIDTH_INDICATOR } from '../editorConstants'
import { QueriedEstimateWorkItem } from '../editorTypes'

const IndicatorsCell = ({ row }: { row: Row<QueriedEstimateWorkItem> }) => {
  const isWorkCompleted = row.original.workOrder?.isWorkCompleted
  const isOverrideLocked = row.original.isOverrideLocked ?? false
  return (
    <IndicatorCell>
      <div className='indicator-wrapper'>
        {isWorkCompleted ? (
          <Tooltip content='Work Completed'>
            <CheckCircleIcon label='Work Completed' primaryColor={token('color.icon.success')} />
          </Tooltip>
        ) : (
          <Tooltip content='Work Not Completed'>
            <CheckCircleOutlineIcon label='Work Not Completed' primaryColor={token('color.background.neutral.subtle.hovered')} />
          </Tooltip>
        )}
      </div>
      <div className='indicator-wrapper'>
        {isOverrideLocked ? (
          <Tooltip content='Price Override Locked'>
            <LockIcon label='Override Locked' />
          </Tooltip>
        ) : (
          <Tooltip content='Price Override Not Locked'>
            <UnlockIcon label='Not Locked' primaryColor={token('color.background.neutral.subtle.hovered')} />
          </Tooltip>
        )}
      </div>
    </IndicatorCell>
  )
}

export default IndicatorsCell

const IndicatorCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${COLUMN_WIDTH_INDICATOR}px;
  height: 100%;
  padding: 0 ${CELL_PADDING_HORIZONTAL}px;

  .indicator-wrapper {
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 24px;
      height: 24px;
    }
  }
`
