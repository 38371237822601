import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import Textfield from '@atlaskit/textfield'
import { token } from '@atlaskit/tokens'

import { GetDispatchPayoutDetailsQuery, SubmitDispatchPayoutMutation, SubmitDispatchPayoutMutationVariables } from '@/gql/graphql'
import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import { graphql } from '@/gql'
import NumberCell from '@/pages/jobs/EstimateEditor/components/NumberCell'
import useGraphQLClient from '@/utils/useAuthRequest'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import InstallerTable, { InstallersEdits } from './InstallersTable'

type WorkOrder = GetDispatchPayoutDetailsQuery['workOrder']
type Dispatch = WorkOrder['dispatches'][number]
type InstallerPayout = NonNullable<SubmitDispatchPayoutMutation['submitDispatchPayout']>['installerPayouts'][number]
const PrefixText = styled.span`
  font-weight: 500;
  color: ${token('color.text.subtlest')};
  margin-left: 8px;
`
const USD_PREFIX = <PrefixText>$</PrefixText>
const CELL_PADD = '4px 8px'

const SelectedDispatch = ({ dispatch, workOrder }: { dispatch: Dispatch; workOrder: WorkOrder }) => {
  const [installerAmounts, setInstallerAmounts] = useState<InstallersEdits>({})
  const [newTargetPayoutBase, setNewTargetPayoutBase] = useState<string | null>(null)
  const [newTargetPayoutAddon, setNewTargetPayoutAddon] = useState<string | null>(null)
  const totalTargetPayout =
    Number(dispatch.laborPayoutBase ?? newTargetPayoutBase ?? 0) + Number(dispatch.laborPayoutAddon ?? newTargetPayoutAddon ?? 0)

  const areChangesToSave = newTargetPayoutBase !== null || newTargetPayoutAddon !== null || Object.values(installerAmounts).length > 0

  useEffect(() => {
    setNewTargetPayoutBase(null)
    setNewTargetPayoutAddon(null)
    setInstallerAmounts({})
  }, [dispatch.date])

  const graphqlClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: SubmitDispatchPayoutMutationVariables) => {
      const response = await graphqlClient.request(SUBMIT_DISPATCH_PAYOUT, variables)
      return response.submitDispatchPayout
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GetDispatchPayoutDetails'] })
      queryClient.invalidateQueries({ queryKey: ['GetPayrollWeekDispatches'] })
      setInstallerAmounts({})
      setNewTargetPayoutBase(null)
      setNewTargetPayoutAddon(null)
    },
  })
  const projectedTotal: number = Number(workOrder?.laborCostTotal ?? 0)
  const projectedAdd: number = Number(workOrder?.totalAddLaborCost ?? 0)
  const projectedBase: number = projectedTotal - projectedAdd

  const totalPaidBaseDb: number = Number(workOrder?.totalPaidBase ?? 0)
  const totalPaidAddonDb: number = Number(workOrder?.totalPaidAddon ?? 0)

  const previousPaidBase: number = Number(dispatch?.previouslyPaidBase ?? 0)
  const previousPaidAddon: number = Number(dispatch?.previouslyPaidAddon ?? 0)
  const previousPaid: number = previousPaidBase + previousPaidAddon

  const targetPayoutBaseDb: number = Number(dispatch.laborPayoutBase ?? 0)
  const targetPayoutAddonDb: number = Number(dispatch.laborPayoutAddon ?? 0)

  const targetPayoutBase: number = Number(newTargetPayoutBase ?? targetPayoutBaseDb)
  const targetPayoutAddon: number = Number(newTargetPayoutAddon ?? targetPayoutAddonDb)
  const targetPayout: number = targetPayoutBase + targetPayoutAddon

  const baseAdjustment: number = targetPayoutBase - targetPayoutBaseDb
  const addonAdjustment: number = targetPayoutAddon - targetPayoutAddonDb

  // const totalPaidBase: number = baseAdjustment + totalPaidBaseDb
  // const totalPaidAddon: number = addonAdjustment + totalPaidAddonDb
  // const totalPaid: number = totalPaidBase + totalPaidAddon

  const remainingBaseDb: number = projectedBase - totalPaidBaseDb
  const remainingAddonDb: number = projectedAdd - totalPaidAddonDb

  const remainingBase: number = remainingBaseDb - baseAdjustment
  const remainingAddon: number = remainingAddonDb - addonAdjustment
  const remainingTotal: number = remainingBase + remainingAddon

  const shortDate = format(new Date(dispatch.date), 'MM/dd')

  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 32, alignSelf: 'flex-start' }}>
      <ControlsRow>
        <h4 style={{ marginBottom: 8 }}>Payout for {shortDate} Dispatch</h4>
        <Button appearance='primary' isDisabled={!areChangesToSave || isPending} isLoading={isPending} onClick={handleSubmit}>
          {isPending ? 'Saving...' : 'Save'}
        </Button>
      </ControlsRow>

      <StyledTable>
        <thead>
          <tr>
            <th className='main-col-1' />
            <th className='main-col-2'>WO Projected</th>
            <th className='main-col-3'>WO Paid</th>
            <th className='main-col-4 selected-column'>{shortDate}</th>
            <th className='main-col-5' style={{ paddingRight: 8 }}>
              WO Remaining
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className='main-col-1 row-header'>Base Labor</td>
            <td className='main-col-2'>
              <NumberCell padding={CELL_PADD} readOnly value={projectedBase} prefix='$' />
            </td>
            <td className='main-col-3'>
              <NumberCell padding={CELL_PADD} readOnly value={previousPaidBase} prefix='$' />
            </td>
            <td className='main-col-4'>
              <div className='input-cell-div-wrapper'>
                <Textfield
                  elemBeforeInput={USD_PREFIX}
                  type='number'
                  id='newTargetPayoutBase'
                  isCompact
                  value={newTargetPayoutBase ?? dispatch.laborPayoutBase ?? '0.00'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTargetPayoutBase(e.target.value)}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => setNewTargetPayoutBase(Number(e.target.value).toFixed(2))}
                  onFocus={handleFocus}
                />
              </div>
            </td>
            <td className='main-col-5'>
              <NumberCell padding={CELL_PADD} readOnly value={remainingBase} prefix='$' />
            </td>
          </tr>

          <tr>
            <td className='main-col-1 row-header'>Add Labor</td>

            <td className='main-col-2'>
              <NumberCell padding={CELL_PADD} readOnly value={projectedAdd} prefix='$' />
            </td>

            <td className='main-col-3'>
              <NumberCell padding={CELL_PADD} readOnly value={previousPaidAddon} prefix='$' />
            </td>

            <td className='main-col-4'>
              <div className='input-cell-div-wrapper'>
                <Textfield
                  elemBeforeInput={USD_PREFIX}
                  type='number'
                  id='newTargetPayoutAddon'
                  isCompact
                  value={newTargetPayoutAddon ?? dispatch.laborPayoutAddon ?? '0.00'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTargetPayoutAddon(e.target.value)}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => setNewTargetPayoutAddon(Number(e.target.value).toFixed(2))}
                  onFocus={handleFocus}
                />
              </div>
            </td>

            <td className='main-col-5'>
              <NumberCell padding={CELL_PADD} readOnly value={remainingAddon} prefix='$' />
            </td>
          </tr>

          <tr>
            <td className='main-col-1 row-header'>Total Payout</td>

            <td className='main-col-2'>
              <NumberCell padding={CELL_PADD} readOnly value={projectedTotal} prefix='$' />
            </td>

            <td className='main-col-3'>
              <NumberCell padding={CELL_PADD} readOnly value={previousPaid} prefix='$' />
            </td>

            <td className='main-col-4'>
              <NumberCell padding={CELL_PADD} readOnly value={targetPayout} prefix='$' />
            </td>

            <td className='main-col-5'>
              <NumberCell padding={CELL_PADD} readOnly value={remainingTotal} prefix='$' />
            </td>
          </tr>
        </tbody>
      </StyledTable>

      <InstallerTable
        dispatch={dispatch}
        totalTargetPayout={totalTargetPayout}
        installerAmounts={installerAmounts}
        setInstallerAmounts={setInstallerAmounts}
      />
    </div>
  )
  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      e.target.select()
    }, 1) // Delay until the next event loop cycle
  }

  function handleSubmit() {
    const installerPayouts: InstallerPayout[] = Object.keys(installerAmounts).map(installerId => ({
      installerId,
      payoutAmount: Number(installerAmounts[installerId]?.payoutAmount ?? 0),
      hoursWorked: Number(installerAmounts[installerId]?.hoursWorked ?? 0),
    }))
    const dispatchId: string = dispatch.id
    const variables = {
      dispatchId,
      payoutBase: Number(targetPayoutBase),
      payoutAddon: Number(targetPayoutAddon),
      installerPayouts,
    }
    console.log('variables', variables)
    mutate(variables)
  }
}

export default SelectedDispatch

const ControlsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 8px;
`

const StyledTable = styled.table`
  width: fit-content;

  td {
    padding: 0;
    font-family: ${MONOSPACE_FONT_FAMILY};
  }
  tbody > tr:nth-child(odd) {
    background-color: ${token('color.background.neutral')};
    .current-week {
      background: ${token('color.background.selected.hovered')};
    }
    td > div > div {
      &:not(:focus-within) {
        background: ${token('color.background.input.hovered')};
      }
    }
  }
  th,
  td {
    text-align: right;

    > div > div {
      display: flex;
      justify-content: flex-end;
      border-radius: 0;
      border-color: ${token('color.border')};
      > span {
        color: ${token('color.text.disabled')};
      }

      input {
        /* max-width: 120px; */
        font-family: ${MONOSPACE_FONT_FAMILY};
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        text-align: right;
        -moz-appearance: textfield;
        appearance: textfield;
      }
    }
    > label {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
  td.main-col-1 {
    width: 100px;
  }
  td.main-col-2 {
    width: 120px;
  }
  td.main-col-3 {
    width: 120px;
  }
  td.main-col-4 {
    width: 120px;
  }
  td.main-col-5 {
    width: 140px;
  }
  th.input-padding {
    padding-right: 14px;
  }
  td.input-padding {
    padding-right: 15px;
  }

  .zero {
    color: ${token('color.text.disabled')};
  }
  .row-header {
    font-family: ${token('font.family.body')};
    font-weight: 500;
  }
  .current-week {
    background: ${token('color.background.selected')};
  }
  .input-cell-div-wrapper {
    display: flex;
    justify-content: flex-end;
    /* border: 1px solid ${token('color.border.bold')}; */
    min-width: 100%;
  }
`

const SUBMIT_DISPATCH_PAYOUT = graphql(/* GraphQL */ `
  mutation SubmitDispatchPayout(
    $dispatchId: ID!
    $payoutBase: Decimal!
    $payoutAddon: Decimal!
    $installerPayouts: [InstallerPayoutType!]!
  ) {
    submitDispatchPayout(dispatchId: $dispatchId, payoutBase: $payoutBase, payoutAddon: $payoutAddon, installerPayouts: $installerPayouts) {
      success
      message
    }
  }
`)
