import { Temporal } from '@js-temporal/polyfill'
import { format } from 'date-fns'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'

import { ButtonGroup } from '@atlaskit/button'
import { IconButton } from '@atlaskit/button/new'
import { DatePicker } from '@atlaskit/datetime-picker'
import { token } from '@atlaskit/tokens'

import EditorCollapseIcon from '@atlaskit/icon/glyph/editor/collapse'
import EditorExpandIcon from '@atlaskit/icon/glyph/editor/expand'

import { onlyMondaysFilter } from '@/utils/utilities'

import { isWeeksExpandedAtom } from '../atoms'

import DownloadCalendarButton from './DownloadCalendarButton'
import ShowUnscheduledButton from './ShowUnscheduledButton'

const CalendarPageActions = ({ firstWeekDate, lastWeekDate }: { firstWeekDate: Temporal.PlainDate; lastWeekDate: Temporal.PlainDate }) => {
  const navigate = useNavigate()

  const [isWeeksExpanded, setIsWeeksExpanded] = useAtom(isWeeksExpandedAtom)

  const firstWeekDateString = firstWeekDate.toString()
  const lastWeekDateString = lastWeekDate.toString()

  return (
    <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
      <div style={{ display: 'flex', gap: 12, alignItems: 'baseline' }}>
        <span style={{ color: token('color.text.disabled') }}>Weeks Range:</span>

        <DatePicker
          appearance='subtle'
          spacing='compact'
          innerProps={{ style: { width: 110 } }}
          onChange={handleFirstWeekDateChange}
          value={firstWeekDateString}
          disabledDateFilter={onlyMondaysFilter}
          formatDisplayLabel={dateString => {
            const dateObj = new Date(dateString + 'T00:00:00')
            const formattedDate = format(dateObj, 'MMM do')
            return formattedDate
          }}
        />

        <DatePicker
          appearance='subtle'
          spacing='compact'
          innerProps={{ style: { width: 110 } }}
          onChange={handleLastWeekDateChange}
          value={lastWeekDateString}
          disabledDateFilter={onlyMondaysFilter}
          formatDisplayLabel={date => format(new Date(date), 'MMM do')}
        />
      </div>

      <ButtonGroup label='Calendar Actions'>
        <DownloadCalendarButton />

        <div style={{ transform: 'rotate(90deg)' }}>
          <IconButton
            isSelected={isWeeksExpanded}
            icon={isWeeksExpanded ? EditorCollapseIcon : EditorExpandIcon}
            label={isWeeksExpanded ? 'Shrink Weeks' : 'Show All'}
            isTooltipDisabled={false}
            onClick={handleExpandWeeks}
          />
        </div>
        <ShowUnscheduledButton />
      </ButtonGroup>
    </div>
  )
  function handleFirstWeekDateChange(date: string) {
    navigate(`/calendar/${date}/${lastWeekDateString}`)
  }
  function handleLastWeekDateChange(date: string) {
    navigate(`/calendar/${firstWeekDateString}/${date}`)
  }
  function handleExpandWeeks() {
    setIsWeeksExpanded(!isWeeksExpanded)
  }
}

export default CalendarPageActions
