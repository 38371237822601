import { useQuery } from '@tanstack/react-query'

import { graphql } from '@/gql'
import useGraphQLClient from '@/utils/useAuthRequest'

const useTripsQuery = () => {
  const graphQLClient = useGraphQLClient()

  const { data, error, status } = useQuery({
    queryKey: [GET_TRIPS_QUERY_KEY],
    queryFn: () => graphQLClient.request(GET_TRIPS),
    refetchInterval: 1000 * 60 * 60, // 60 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  })

  const trips = data?.trips ?? []
  return { trips, error, status }
}

export default useTripsQuery

export const GET_TRIPS_QUERY_KEY = 'trips'
export const GET_TRIPS = graphql(/* GraphQL */ `
  query GetTrips {
    trips {
      id
      isEnabled
      name
    }
  }
`)
