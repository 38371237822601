import { useCallback, useState } from 'react'

import Button from '@atlaskit/button/new'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import PrinterIcon from '@atlaskit/icon/core/printer'
import PrintDayDispatchesForm from './PrintDayDispatchesForm'
interface PrintDayDispatchesModalProps {
  dateString: string | null
}

export default function PrintDayDispatchesModal({ dateString }: PrintDayDispatchesModalProps) {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Button onClick={() => setIsOpen(true)} iconBefore={PrinterIcon}>
        Work Orders
      </Button>

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <PrintDayDispatchesForm closeModal={closeModal} dateString={dateString} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}
