import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { modifierKeysPressedAtom } from '@/utils/useWatchModifierKeys'

const useIsShiftKeyPressed = () => {
  const shiftKeyPressedAtom = useMemo(() => selectAtom(modifierKeysPressedAtom, keysPressed => keysPressed.shift), [])
  return useAtomValue(shiftKeyPressedAtom)
}

export default useIsShiftKeyPressed
