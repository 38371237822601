import { useSetAtom } from 'jotai'
import { useEffect } from 'react'

import {
  databaseEstimateAtom,
  databaseWorkItemsAtom,
  editingCellAtom,
  WorkItemEdits,
  workItemEditsQueueAtom,
  workItemsOptimisticAttributesAtom,
} from '../editorAtoms'

const useAtomCleanup = () => {
  const setDatabaseEstimate = useSetAtom(databaseEstimateAtom)
  const setDatabaseWorkItems = useSetAtom(databaseWorkItemsAtom)
  const setWorkItemsOptimisticAttributes = useSetAtom(workItemsOptimisticAttributesAtom)
  const setWorkItemEditsQueue = useSetAtom(workItemEditsQueueAtom)
  const setEditingCell = useSetAtom(editingCellAtom)

  useEffect(() => {
    // cleanup function to reset atoms when leaving the page
    return () => {
      console.log('useAtomCleanup(): Resetting estimate data')
      setDatabaseEstimate(null)
      setDatabaseWorkItems({})
      setWorkItemsOptimisticAttributes({})
      setWorkItemEditsQueue([])
      setEditingCell({ rowId: null, columnId: null, edits: {} as WorkItemEdits })
    }
  }, [setDatabaseEstimate, setDatabaseWorkItems, setWorkItemsOptimisticAttributes, setWorkItemEditsQueue, setEditingCell])
}

export default useAtomCleanup
