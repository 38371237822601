import { useSetAtom } from 'jotai'

import Checkbox from '@atlaskit/checkbox'

import { workItemEditsQueueAtom, workItemsOptimisticAttributesAtom } from '../editorAtoms'

import useWorkItemAttribute from '../hooks/useWorkItemAttribute'

interface CheckboxCellProps {
  workItemId: string | null
  attribute: 'isVisibleOnQuote' | 'isVisibleOnWorkOrder'
  isDisabled: boolean
}

const CheckboxCell = ({ workItemId, attribute, isDisabled }: CheckboxCellProps) => {
  const isChecked = useWorkItemAttribute(attribute, workItemId ?? 'null')

  const setWorkItemEditsQueue = useSetAtom(workItemEditsQueueAtom)
  const setOptimisticAttributes = useSetAtom(workItemsOptimisticAttributesAtom)

  const handleChange = (isChecked: boolean) => {
    if (isDisabled) return
    setOptimisticAttributes(prev => {
      const key = workItemId ?? 'null'
      const existingWorkItem = prev?.[key] ?? {}
      console.log('setOptimisticAttributes: ', { key, isChecked, existingWorkItem })
      return { ...prev, [key]: { ...existingWorkItem, [attribute]: isChecked } }
    })
    // @ts-expect-error - workItemId is null
    setWorkItemEditsQueue(prev => {
      const valueToSubmit = isChecked
      return [...prev, { workItemId, attribute, value: valueToSubmit }]
    })
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Checkbox isChecked={isChecked} onChange={e => handleChange(e.target.checked)} size='large' isDisabled={isDisabled} />
    </div>
  )
}

export default CheckboxCell
