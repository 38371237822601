import { useSetAtom } from 'jotai'
import Select, { components, OptionProps, Theme } from 'react-select'

import { token } from '@atlaskit/tokens'

import useWorkAreasQuery from '@/utils/queryHooks/useWorkAreasQuery'

import { GetWorkAreasQuery } from '@/gql/graphql'

import { workItemEditsQueueAtom, workItemsOptimisticAttributesAtom } from '../editorAtoms'
import { UNIVERSAL_EDITOR_SELECT_PROPS } from '../editorConstants'

import useSelectColors from '../hooks/useSelectColors'
import useWorkItemAttribute from '../hooks/useWorkItemAttribute'

type WorkArea = GetWorkAreasQuery['workAreas'][0]
type WorkAreaOption = {
  label: string
  value: string
  workArea: WorkArea
}

interface WorkAreaCellProps {
  workItemId: string | null
}

const WorkAreaCell = ({ workItemId }: WorkAreaCellProps) => {
  const setWorkItemEditsQueue = useSetAtom(workItemEditsQueueAtom)
  const setOptimisticAttributes = useSetAtom(workItemsOptimisticAttributesAtom)

  const { workAreas } = useWorkAreasQuery()
  const options = workAreas.map((workArea: WorkArea) => ({ label: workArea.name, value: workArea.id, workArea: workArea }))

  const workArea = useWorkItemAttribute('workArea', workItemId ?? 'null')

  const handleChange = (option: WorkAreaOption | null) => {
    setOptimisticAttributes(prev => {
      const key = workItemId ?? 'null'
      const existingWorkItem = prev?.[key] ?? {}
      console.log('setOptimisticAttributes: ', { key, option, existingWorkItem })
      return {
        ...prev,
        [key]: {
          ...existingWorkItem,
          workArea: option?.workArea,
          workAreaId: option?.value ? Number(option.value) : null,
        },
      }
    })

    // @ts-expect-error Doesn't like JSON value
    if (workItemId !== 'NEW') setWorkItemEditsQueue(prev => [...prev, { workItemId, attribute: 'workAreaId', value: option.value }])
  }

  const colors = useSelectColors()

  return (
    <div style={{ width: '100%', minWidth: 140 }}>
      <Select
        options={options}
        components={{ Option: WorkAreaOption, IndicatorSeparator: null, DropdownIndicator: null }}
        value={!workArea?.id ? null : { label: workArea.name, value: workArea.id }}
        placeholder='Select WorkArea'
        inputId='workArea-selector'
        classNamePrefix='workArea-selector'
        onChange={handleChange}
        theme={(theme: Theme) => ({
          ...theme,
          borderRadius: 0,
          colors,
        })}
        {...UNIVERSAL_EDITOR_SELECT_PROPS}
      />
    </div>
  )
}

export default WorkAreaCell

const WorkAreaOption = (props: OptionProps<WorkAreaOption>) => {
  const { workArea } = props.data
  const isSelected = props.isSelected

  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ fontSize: '11px', color: isSelected ? token('color.text.inverse') : token('color.icon.accent.gray') }}>
          {workArea.category}
        </div>
        <div style={{ fontSize: '13px' }}>{workArea.name}</div>
      </div>
    </components.Option>
  )
}
