import { Temporal } from '@js-temporal/polyfill'
import { useAtom } from 'jotai'

import AppRightPanel from '@/components/AppRightPanel'

import { MONTH_NAMES_LIST } from '@/utils/constants'

import { dateStringSelectedAtom, workOrderSelectedAtom } from './atoms'

import RightPanelSelectedDate from './RightPanelSelectedDate'
import RightPanelSelectedWorkOrder from './RightPanelSelectedWorkOrder'

const RIGHT_PANEL_WIDTH = 400

interface RightPanelContainerProps {
  width?: number
}

const RightPanelContainer = ({ width = RIGHT_PANEL_WIDTH }: RightPanelContainerProps) => {
  const [dateStringSelected, setDateSelected] = useAtom(dateStringSelectedAtom)
  const [workOrderSelected, setWorkOrderSelected] = useAtom(workOrderSelectedAtom)

  const closeRightPanel = () => (workOrderSelected ? setWorkOrderSelected(null) : setDateSelected(null))

  let title: string = 'title'
  if (workOrderSelected?.workOrder) {
    title = 'Work Order ' + workOrderSelected.workOrderId + '-' + workOrderSelected.workOrder.name
  } else if (dateStringSelected) {
    const date = Temporal.PlainDate.from(dateStringSelected)
    const monthName = MONTH_NAMES_LIST[date.month - 1].slice(0, 3)
    title = `${monthName} ${date.day}`
  }

  return (
    <AppRightPanel
      isPanelOpen={Boolean(dateStringSelected || workOrderSelected)}
      handleClose={closeRightPanel}
      panelWidth={width}
      title={title}
      topBarLeftActions={<div />}
    >
      {workOrderSelected ? (
        <RightPanelSelectedWorkOrder workOrderSelected={workOrderSelected} />
      ) : dateStringSelected ? (
        <RightPanelSelectedDate dateString={dateStringSelected} />
      ) : null}
    </AppRightPanel>
  )
}

export default RightPanelContainer
