import { useMemo, useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import HoveredDropZone from '@/components/HoveredDropZone'

import type { Vehicle } from '@/utils/queryHooks/useVehiclesQuery'
import { COLOR_OPTIONS, type CardColorOptions } from '@/utils/utilities'

import useDropZoneVehicleContainer, { useIsVehicleBodyHoveredByDispatch } from '../hooks/useDropZoneVehicleContainer'
import useSelectedVehicleDispatchIds from '../selectors/useSelectedVehicleDispatchIds'

import { VEHICLE_DISPATCH_CARD_GAP, VEHICLE_DISPATCH_CARD_HEIGHT } from '../../dispatchConstants'

import LoadSheetSection from './LoadSheetSection'
import VehicleContainerHeader from './VehicleContainerHeader'
import VehicleDispatchContainer from './VehicleDispatchContainer'

interface VehicleContainerProps {
  vehicle: Vehicle
  dateString: string
}

const VehicleContainer = ({ vehicle, dateString }: VehicleContainerProps) => {
  const vehicleBodyRef = useRef<HTMLDivElement | null>(null)

  const vehicleId = vehicle.id
  const { isHovered: isBodyHovered, alreadyExists: dispatchAlreadyExists } = useIsVehicleBodyHoveredByDispatch(vehicle.id)
  const { vehicleDispatchIds, moveOrder } = useSelectedVehicleDispatchIds(vehicleId, dateString)

  useDropZoneVehicleContainer({ vehicleId, vehicleBodyRef })
  const color: CardColorOptions = useMemo(
    // @ts-expect-error - vehicle.color is not always a CardColorOptions
    () => (COLOR_OPTIONS.includes(vehicle?.color ?? '') ? (vehicle.color as CardColorOptions) : ('gray' as CardColorOptions)),
    [vehicle]
  )
  return (
    <VehicleContainerCard $color={color} $dispatchAlreadyExists={dispatchAlreadyExists} $vehicleCount={vehicleDispatchIds.length}>
      <VehicleContainerHeader vehicle={vehicle} dateString={dateString} color={color} />

      <div className='load-sheet-section'>
        <LoadSheetSection vehicleId={vehicleId} dateString={dateString} />
      </div>

      <div className='vehicle-body-section' ref={vehicleBodyRef}>
        <HoveredDropZone
          isHovered={isBodyHovered}
          alreadyExists={dispatchAlreadyExists}
          hoveredText={dispatchAlreadyExists ? 'Already Assigned' : 'Assign Dispatch'}
          hoveredTextLineTwo=''
        />

        {vehicleDispatchIds.length === 0 ? (
          <div className='vehicle-body-no-dispatches'>
            <h4>No Dispatches Assigned</h4>
          </div>
        ) : (
          vehicleDispatchIds.map((vehicleDispatchId, index) => (
            <VehicleDispatchContainer
              key={vehicleDispatchId}
              dateString={dateString}
              vehicleDispatchId={vehicleDispatchId}
              vehicleId={vehicleId}
              moveOrder={moveOrder}
              currentIndex={index}
              isLast={index === vehicleDispatchIds.length - 1}
            />
          ))
        )}
      </div>
    </VehicleContainerCard>
  )
}

export default VehicleContainer

interface VehicleContainerCardProps {
  $color: CardColorOptions
  $dispatchAlreadyExists: boolean
  $vehicleCount: number
}

const VehicleContainerCard = styled.div<VehicleContainerCardProps>`
  border: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};
  border-radius: 4px;
  min-width: 500px;
  flex-shrink: 0;
  /* overflow: hidden; */

  .load-sheet-section {
    box-sizing: border-box;
    transform: translateY(-1px);
    border-bottom: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};
    background: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.background.accent.${$color}.subtlest`))};
    > div {
      background: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.background.accent.${$color}.subtlest`))};
    }
    .ant-table-container {
      border: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};
      overflow: hidden;
    }
  }

  .vehicle-body-section {
    position: relative;
    background: ${token('elevation.surface.sunken')};

    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    min-height: 60px;
    height: ${({ $vehicleCount }) => $vehicleCount * (VEHICLE_DISPATCH_CARD_HEIGHT + VEHICLE_DISPATCH_CARD_GAP)}px;
    transition: height 400ms cubic-bezier(0.4, 0, 0.2, 1);

    padding: ${VEHICLE_DISPATCH_CARD_GAP}px ${VEHICLE_DISPATCH_CARD_GAP + 4}px;

    overflow: hidden;

    .vehicle-body-no-dispatches {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      inset: 0;
      bottom: 10%;
      z-index: 3;

      h4 {
        font-size: 24px;
        font-weight: 600;
        color: ${token('color.text.disabled')};
        opacity: 0.6;
      }
    }
    .vehicle-body-hovered-by-dispatch-section {
      position: absolute;
      inset: 4px;
      background: ${({ $dispatchAlreadyExists }) =>
        $dispatchAlreadyExists ? token('color.background.accent.gray.subtlest.hovered') : token('color.background.selected.hovered')};
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      color: ${({ $dispatchAlreadyExists }) =>
        $dispatchAlreadyExists ? token('color.text.accent.gray') : token('color.text.accent.blue')};
      z-index: 3;
      border: 1px solid ${token('color.border.accent.gray')};
    }
  }
`
