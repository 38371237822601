import InlineEdit from '@atlaskit/inline-edit'
import TextArea from '@atlaskit/textarea'
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import styled from 'styled-components'

import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'
import { GetCustomerQuery, SubmitCustomerValuesMutationVariables } from '@/gql/graphql'

type CustomerType = GetCustomerQuery['customer']
interface CustomerPageTabNotesProps {
  customer: CustomerType | null
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<GetCustomerQuery, Error>>
}

const CustomerPageTabNotes = ({ customer, refetch }: CustomerPageTabNotesProps) => {
  const [editValue, setEditValue] = useState<string | null>(null)

  const graphQLClient = useGraphQLClient()
  const { mutate } = useMutation({
    mutationFn: async (variables: SubmitCustomerValuesMutationVariables) => graphQLClient.request(SUBMIT_CUSTOMER_VALUE, variables),
    onSuccess: () => refetch().then(() => setEditValue(null)),
    onError: () => setEditValue(null),
  })

  const customerNotes = editValue ?? customer?.workOrderNotes ?? ''
  return (
    <div style={{ display: 'flex', position: 'relative', width: '100%' }}>
      <Container>
        <InlineEdit
          defaultValue={customerNotes}
          label='Builder Notes to appear on printed Work Orders'
          editButtonLabel={customerNotes}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          editView={({ errorMessage, ...fieldProps }, ref) => (
            // @ts-expect-error known error per atlaskit
            <TextArea {...fieldProps} ref={ref} />
          )}
          readView={() => <ReadViewContainer>{customerNotes || 'Click here to add builder notes'}</ReadViewContainer>}
          onConfirm={onConfirm}
          keepEditViewOpenOnBlur
          readViewFitContainerWidth
        />
      </Container>
    </div>
  )
  function onConfirm(value: string) {
    if (value === customer?.workOrderNotes) {
      setEditValue(null)
      return
    }
    setEditValue(value)
    const variables = {
      customerId: customer?.id ?? '',
      workOrderNotes: value,
    }
    console.log('onConfirm', variables)
    mutate({ customerId: customer?.id ?? '', attribute: 'work_order_notes', value: value })
  }
}

export default CustomerPageTabNotes

// Styled-components for the container and readView styles
const Container = styled.div`
  padding-block-start: 8px; /* token('space.100') equivalent */
  padding-inline-end: 8px;
  padding-block-end: 24px; /* token('space.600') equivalent */
  width: 70%;
`

const ReadViewContainer = styled.div`
  min-height: 200px;
  padding: 6px; /* token('space.075') equivalent */
  word-break: break-word;
  margin-left: -8px;
`

const SUBMIT_CUSTOMER_VALUE = graphql(/* GraphQL */ `
  mutation SubmitCustomerValues($customerId: UUID!, $attribute: String!, $value: JSON) {
    submitCustomerValues(customerId: $customerId, attribute: $attribute, value: $value) {
      success
      message
    }
  }
`)
