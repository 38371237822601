import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { databaseWorkItemsAtom, workItemsOptimisticAttributesAtom } from '../editorAtoms'
import { QueriedEstimateWorkItem } from '../editorTypes'

const useWorkItemAttribute = (key: keyof QueriedEstimateWorkItem, id: string): QueriedEstimateWorkItem[keyof QueriedEstimateWorkItem] => {
  const databaseValueAtom = useMemo(() => selectAtom(databaseWorkItemsAtom, items => items[id]?.[key] ?? null), [id, key])
  const optimisticValueAtom = useMemo(() => selectAtom(workItemsOptimisticAttributesAtom, items => items[id]?.[key]), [id, key])
  const databaseValue = useAtomValue(databaseValueAtom)
  const optimisticValue = useAtomValue(optimisticValueAtom)
  return optimisticValue !== undefined ? optimisticValue : databaseValue
}
export default useWorkItemAttribute
