import type { TableProps } from 'antd'
import { ConfigProvider, Table } from 'antd'

import { GetJobQuery } from '@/gql/graphql'
import useAntdTableTheme from '@/utils/useAntdTableTheme'
import { formatCurrencyString } from '@/utils/utilities'

import { IconButton } from '@atlaskit/button/new'
import MoreIcon from '@atlaskit/icon/glyph/more'
import Link from '@atlaskit/link'

type WorkOrdersType = GetJobQuery['job']['estimates'][0]['workOrders']

interface WorkOrdersTableProps {
  workOrders: WorkOrdersType
  status: 'error' | 'success' | 'pending'
}
// const linkSelector = (row: EstimatesType[0]) => `/jobs/${row.jobId}/estimates/${row.id}`

const WorkOrdersTable = ({ workOrders, status }: WorkOrdersTableProps) => {
  const theme = useAntdTableTheme()

  return (
    <div style={{ display: 'flex', position: 'relative', maxWidth: '100%' }}>
      <ConfigProvider theme={theme}>
        <Table rowKey='id' columns={columns} dataSource={workOrders} size='small' loading={status === 'pending'} pagination={false} />
      </ConfigProvider>
    </div>
  )
}

export default WorkOrdersTable

const columns: TableProps<WorkOrdersType[0]>['columns'] = [
  {
    key: 'id',
    title: 'WO#',
    render: workOrder => <Link href={`/work-order/${workOrder.id}`}>{workOrder.id}</Link>,
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'WO Title',
    // width: 240,
    // fixed: 'left',
    // render: ({ title, id, jobId }) => <Link href={`/jobs/${jobId}/estimates/${id}`}>{title}</Link>,
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: 'Status',
  },

  {
    key: 'materialCostTotal',
    dataIndex: 'materialCostTotal',
    title: 'Material Cost',
    align: 'right',

    render: text => formatCurrencyString(text),
  },
  {
    key: 'laborCostTotal',
    dataIndex: 'laborCostTotal',
    title: 'Labor Cost',
    align: 'right',

    render: text => formatCurrencyString(text),
  },
  {
    key: 'marginPercent',
    dataIndex: 'marginPercent',
    title: 'Margin',
    align: 'right',

    render: text => (!text ? '-' : text + '%'),
  },
  {
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    title: 'Total Price',
    align: 'right',

    render: text => formatCurrencyString(text),
  },
  {
    key: 'deferScheduleDate',
    dataIndex: 'deferScheduleDate',
    title: 'Defer Till',
    align: 'right',

    render: text => text ?? '-',
  },
  {
    key: 'nextDispatchDate',
    dataIndex: 'nextDispatchDate',
    title: 'Next Dispatch',
    align: 'right',

    render: text => text ?? '-',
  },
  {
    key: 'actions',
    align: 'right',
    render: workOrder => <CreateWorkOrdersIconButton workOrder={workOrder} />,
  },
]

const CreateWorkOrdersIconButton = ({ workOrder }: { workOrder: WorkOrdersType[0] }) => {
  // const graphQLClient = useGraphQLClient()
  // const queryClient = useQueryClient()

  function handleClick() {
    console.log('Doing something to work order: ', workOrder)
    // mutate()
  }

  return (
    <IconButton
      icon={MoreIcon}
      // isLoading={isPending}
      label='Do WO Stuff'
      onClick={handleClick}
      isTooltipDisabled={false}
      // isDisabled={workOrdersCount > 0}
    />
  )
}

// const CREATE_WORK_ORDER = graphql(/* GraphQL */ `
//   mutation CreateWorkOrders($estimateId: UUID!) {
//     createWorkOrders(estimateId: $estimateId) {
//       success
//       message
//     }
//   }
// `)
