import { useMutation } from '@tanstack/react-query'
import Papa from 'papaparse'
import { useCallback, useRef, useState } from 'react'
import { Controller, ControllerRenderProps, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { DatePicker } from '@atlaskit/datetime-picker'
import { ErrorMessage, Label } from '@atlaskit/form'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { token } from '@atlaskit/tokens'

import useStaffListQuery from '@/utils/queryHooks/useStaffListQuery'
import useGraphQLClient from '@/utils/useAuthRequest'
import { cellNumberStringFromValue } from '@/utils/utilities'

import { graphql } from '@/gql'
import { CreateEstimateMutationVariables, GetStaffListQuery } from '@/gql/graphql'

import useHandlePaste from './useHandlePaste'

type SelectOption = { label: string; value: string }

const statusOptions: readonly SelectOption[] = [
  { label: 'Pending', value: 'pending' },
  { label: 'Active', value: 'active' },
  { label: 'Completed', value: 'completed' },
  { label: 'On Hold', value: 'on-hold' },
  { label: 'In Progress', value: 'in-progress' },
]

type FormInputs = {
  selectedStatus: SelectOption
  selectedSalesperson: SelectOption
  title: string
  startDate: string
}

type CreateEstimateFormProps = {
  closeModal: () => void
  jobTitle: string
  jobId: string
  estimateNames: string[]
}

type SalesPerson = GetStaffListQuery['users'][0]

export default function CreateEstimateForm({ closeModal, jobTitle, jobId, estimateNames }: CreateEstimateFormProps) {
  const navigate = useNavigate()
  const [importedWorkItems, setImportedWorkItems] = useState<CsvWorkItem[]>([])
  const [customErrorMessages, setCustomErrorMessages] = useState<string[]>([])
  const [customWarningMessages, setCustomWarningMessages] = useState<string[]>([])
  const inputFile = useRef<HTMLInputElement>(null)

  const graphQLClient = useGraphQLClient()

  const { staffList } = useStaffListQuery()
  const salesPersonOptions = staffList.map((salesPerson: SalesPerson) => ({ label: salesPerson.fullName, value: salesPerson.id }))
  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: CreateEstimateMutationVariables) => graphQLClient.request(CREATE_ESTIMATE, variables),
    onSuccess: data => navigate(`/jobs/${jobId}/estimates/${data.createEstimate?.estimate?.id}`),
    onError: error => console.error('Error creating estimate: ', error),
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormInputs>()

  const handleClose = useCallback(() => {
    reset()
    closeModal()
  }, [reset, closeModal])

  const { handlePaste, pastedData } = useHandlePaste({ setCustomErrorMessages, setCustomWarningMessages })

  const allWorkItems = [...importedWorkItems, ...pastedData]
  const importedWorkItemsTotal = allWorkItems.reduce((acc, item) => acc + parseCurrencyStringToNumber(item.totalPrice), 0)

  const onSubmit: SubmitHandler<FormInputs> = ({ title, selectedStatus, startDate, selectedSalesperson }) => {
    const variables: CreateEstimateMutationVariables = {
      title,
      status: selectedStatus.value,
      salespersonId: selectedSalesperson.value,
      startDate,
      jobId,
      importedWorkItems: allWorkItems,
    }
    console.log('CreateEstimateForm onSubmit variables: ', variables)
    mutate(variables)
  }

  const formIsInvalid = Object.keys(errors).length > 0
  if (formIsInvalid) console.log('CreateEstimateForm form errors: ', errors)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <ModalTitle>New Estimate For {jobTitle}</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <StyledModalContent>
          <FieldWrapper>
            <Controller
              name='title'
              control={control}
              defaultValue=''
              rules={{
                required: true,
                minLength: 3,
                maxLength: 255,
                validate: value => !estimateNames.includes(value),
              }}
              render={({ field }) => (
                <>
                  <Label htmlFor='basic-textfield'>Estimate Title</Label>
                  <Textfield isInvalid={!!errors?.title} {...field} />

                  {errors?.title?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
                  {errors?.title?.type === 'required' ? <ErrorMessage>Must include an estimate title.</ErrorMessage> : null}
                  {errors?.title?.type === 'validate' ? <ErrorMessage>An estimate with this name already exists.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Controller
              name='selectedStatus'
              control={control}
              defaultValue={statusOptions[0]}
              rules={{ required: true, minLength: 3, maxLength: 255 }}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <Label htmlFor='basic-textfield'>Estimate Status</Label>
                  <Select
                    placeholder='Select Status'
                    menuPosition={'fixed'}
                    options={statusOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    ref={ref}
                  />

                  {errors?.selectedStatus?.type === 'required' ? <ErrorMessage>Must select a customer.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Controller
              name='selectedSalesperson'
              control={control}
              defaultValue={salesPersonOptions[0]}
              rules={{ required: true, minLength: 3, maxLength: 255 }}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <Label htmlFor='basic-textfield'>Salesperson</Label>
                  <Select
                    placeholder='Select Salesperson'
                    menuPosition={'fixed'}
                    options={salesPersonOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    ref={ref}
                  />

                  {errors?.selectedStatus?.type === 'required' ? <ErrorMessage>Must select a customer.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Controller name='startDate' control={control} defaultValue={new Date().toISOString().slice(0, 10)} render={renderStartDate} />
          </FieldWrapper>

          <FieldWrapper>
            <Label htmlFor='basic-textfield'>Paste Here</Label>
            <Textfield
              placeholder='Paste CSV data here'
              onPaste={handlePaste}
              value={!pastedData ? '' : `Found ${pastedData.length} Items on Clipboard`}
            />
          </FieldWrapper>

          <Button onClick={handleFileClick}>Select files</Button>
          {allWorkItems.length > 0 && (
            <div style={{ marginTop: 12 }}>
              <p>
                Found {allWorkItems.length} Work Items Totalling ${cellNumberStringFromValue(importedWorkItemsTotal, 2)}
              </p>
            </div>
          )}
          <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleFileSelect} />

          <ErrorContainer>
            {customErrorMessages.length > 0 && (
              <div style={{ marginTop: 12 }}>
                <h4>Errors:</h4>
                <p>These will prevent the estimate from being created.</p>
                <ul>
                  {customErrorMessages.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
          </ErrorContainer>

          <WarningContainer>
            {customWarningMessages.length > 0 && (
              <div style={{ marginTop: 12 }}>
                <h4>Warnings:</h4>
                <p>These won't stop the estimate from being created, but be sure they are what you want them to be.</p>
                <ul>
                  {customWarningMessages.map((warning, index) => (
                    <li key={index}>{warning}</li>
                  ))}
                </ul>
              </div>
            )}
          </WarningContainer>
        </StyledModalContent>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={handleClose} isDisabled={isPending}>
          Close
        </Button>

        <Button isLoading={isPending} appearance='primary' type='submit' isDisabled={formIsInvalid || customErrorMessages.length > 0}>
          Create
        </Button>
      </ModalFooter>
    </form>
  )
  function handleFileClick() {
    if (inputFile?.current) inputFile.current.click()
  }
  function handleFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0]
    if (file) {
      // Check if the file is a CSV
      if (file.type === 'text/csv') {
        const reader = new FileReader()
        reader.onload = e => {
          if (!e.target?.result) return
          const csvData = e.target.result
          // error if not a string
          if (typeof csvData !== 'string') {
            alert('Please upload a valid CSV file.')
            return
          }
          const parsedData = parseCsvToJson(csvData)
          setImportedWorkItems(parsedData)
        }
        reader.readAsText(file)
      } else alert('Please upload a CSV file.')
    }
  }
}

const parseCsvToJson = (csvData: string): CsvWorkItem[] => {
  let parsedData: CsvWorkItem[] = []
  Papa.parse<CsvWorkItem>(csvData, {
    header: true, // Assuming the first row is the header
    skipEmptyLines: true, // Skip empty lines
    complete: result => {
      parsedData = result?.data ?? []
    },
  })
  return parsedData
}
const FieldWrapper = styled.div`
  margin-bottom: 18px;
`
const StyledModalContent = styled.div`
  padding-bottom: 100px;
`

const ErrorContainer = styled.div`
  color: ${token('color.text.danger')};
  font-size: 0.9em;

  h4 {
    color: ${token('color.text.danger')};
    font-size: 1.2em;
  }

  p,
  ul {
    font-size: 0.9em;
  }
`

const WarningContainer = styled.div`
  color: ${token('color.text.accent.yellow')};
  font-size: 0.9em;

  h4 {
    color: ${token('color.text.accent.yellow')};
    font-size: 1.2em;
  }

  p,
  ul {
    font-size: 0.9em;
  }
`

const CREATE_ESTIMATE = graphql(/* GraphQL */ `
  mutation CreateEstimate(
    $jobId: UUID!
    $title: String!
    $salespersonId: UUID
    $startDate: Date
    $status: String!
    $importedWorkItems: [WorkItemRawInput!]
  ) {
    createEstimate(
      title: $title
      jobId: $jobId
      salespersonId: $salespersonId
      startDate: $startDate
      status: $status
      importedWorkItems: $importedWorkItems
    ) {
      result {
        success
        message
      }
      estimate {
        id
        jobId
        title
        startDate
        status
        salespersonId
        salesperson {
          id
          fullName
        }
      }
    }
  }
`)

function parseCurrencyStringToNumber(currencyString: string): number {
  return Number(currencyString.replace(/[^0-9.-]+/g, ''))
}

interface CsvWorkItem {
  workArea: string
  itemCode: string
  displayName: string
  grouping: string
  trip: string
  quantity: string
  unitOfMeasure: string
  needed: string
  costPerUnit: string
  materialCost: string
  laborCostPerUnit: string
  laborCost: string
  totalCost: string
  markUp: string
  pricePerUnit: string
  totalPrice: string
  hiddenOnQuote: string
  hiddenOnWorkOrders: string
}

function renderStartDate({ field: { onChange, onBlur, value, name, ref } }: { field: ControllerRenderProps<FormInputs> }) {
  return (
    <>
      <Label htmlFor='basic-textfield'>Expected Start Date</Label>
      <DatePicker
        innerProps={{ style: { width: 150 } }}
        selectProps={{
          // @ts-expect-error weirdly typed
          inputId: 'startDate',
          name,
          ref,
        }}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={typeof value === 'string' ? value : value.value}
      />
    </>
  )
}
