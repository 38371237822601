import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler'
import { token } from '@atlaskit/tokens'
import { COLUMN_WIDTH_DRAG } from '../editorConstants'

const DragHandleCell = () => {
  return (
    <div
      style={{
        width: COLUMN_WIDTH_DRAG,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }}
    >
      <DragHandlerIcon label='Drag' primaryColor={token('color.icon.disabled')} />
    </div>
  )
}

export default DragHandleCell
