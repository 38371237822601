import { atom, useSetAtom } from 'jotai'
import { useCallback, useEffect } from 'react'

export interface ModifierKeysPressed {
  shift: boolean
  control: boolean
  alt: boolean
}

export const modifierKeysPressedAtom = atom<ModifierKeysPressed>({ shift: false, control: false, alt: false })

const useWatchModifierKeys = () => {
  const setModifierKeysPressed = useSetAtom(modifierKeysPressedAtom)

  // Handler for keydown event
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        e.preventDefault() // Prevent default behavior
        setModifierKeysPressed(prev => ({ ...prev, shift: true }))
      } else if (e.key === 'Control') setModifierKeysPressed(prev => ({ ...prev, control: true }))
      else if (e.key === 'Alt') setModifierKeysPressed(prev => ({ ...prev, alt: true }))
    },
    [setModifierKeysPressed]
  )

  // Handler for keyup event
  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Shift') setModifierKeysPressed(prev => ({ ...prev, shift: false }))
      else if (e.key === 'Control') setModifierKeysPressed(prev => ({ ...prev, control: false }))
      else if (e.key === 'Alt') setModifierKeysPressed(prev => ({ ...prev, alt: false }))
    },
    [setModifierKeysPressed]
  )

  useEffect(() => {
    // Add event listeners for keydown and keyup
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown, handleKeyUp])
}

export default useWatchModifierKeys
