import { Temporal } from '@js-temporal/polyfill'
import * as Sentry from '@sentry/react'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import PageHeader from '@atlaskit/page-header'

import AppRightSidebarCalendar from '@/components/AppRightSidebarCalendar'

import CalendarPageActions from './components/CalendarPageActions'
import CalendarWeeks from './components/CalendarWeeks'

import useDragDropMonitor from './hooks/useDragDropMonitor'

import { dateStringSelectedAtom, isUnscheduledVisibleAtom, workOrderSelectedAtom } from './atoms'

import RightPanelContainer from './RightPanelContainer'
import SidebarUnscheduledWorkOrders from './SidebarUnscheduledWorkOrders'

const RIGHT_PANEL_WIDTH_SELECTED_WORK_ORDER = 400
const RIGHT_PANEL_WIDTH_SELECTED_DATE = 240
const DEFAULT_WEEKS_COUNT = 6
const SIDEBAR_WIDTH_UNSCHEDULED = 240

const CalendarPage = () => {
  const params = useParams()
  const isUnscheduledVisible = useAtomValue(isUnscheduledVisibleAtom)
  const dateStringSelected = useAtomValue(dateStringSelectedAtom)
  const workOrderSelected = useAtomValue(workOrderSelectedAtom)

  const today = Temporal.Now.plainDateISO() // current date

  const { firstWeekDate, firstWeekDateString, lastWeekDate, lastWeekDateString } = useMemo(() => {
    const dayOfWeek = today.dayOfWeek
    const weekRangeStart = today.subtract({ days: dayOfWeek === 7 ? 6 : 7 + (dayOfWeek - 1) })
    const weekRangeEnd = weekRangeStart.add({ days: 7 * DEFAULT_WEEKS_COUNT - (dayOfWeek - 1) })
    const firstWeekDate = params.firstWeekDate ? Temporal.PlainDate.from(params.firstWeekDate) : weekRangeStart
    const lastWeekDate = params.lastWeekDate ? Temporal.PlainDate.from(params.lastWeekDate) : weekRangeEnd
    return {
      firstWeekDate,
      lastWeekDate,
      firstWeekDateString: firstWeekDate.toString(),
      lastWeekDateString: lastWeekDate.toString(),
    }
  }, [today, params.firstWeekDate, params.lastWeekDate])

  useDragDropMonitor(firstWeekDateString, lastWeekDateString)

  const rightSideBarWidth = workOrderSelected
    ? RIGHT_PANEL_WIDTH_SELECTED_WORK_ORDER
    : dateStringSelected
      ? RIGHT_PANEL_WIDTH_SELECTED_DATE
      : 0

  const unscheduledWidth = isUnscheduledVisible ? SIDEBAR_WIDTH_UNSCHEDULED : 0
  const actions = <CalendarPageActions firstWeekDate={firstWeekDate} lastWeekDate={lastWeekDate} />

  return (
    <PageWrapper $unscheduledWidth={unscheduledWidth} $rightSideBarWidth={rightSideBarWidth}>
      <title>Calendar - Thermal Shop Admin</title>
      <div className='column-without-sidebar'>
        <div className='header-row'>
          <div className='header-row-main'>
            <div className='header-padding'>
              <PageHeader actions={actions}>Calendar</PageHeader>
            </div>
          </div>

          <div className='header-row-unscheduled'></div>
        </div>

        <div className='content-row'>
          <div className='main-section'>
            <CalendarWeeks firstWeekDate={firstWeekDate} lastWeekDate={lastWeekDate} today={today} />
          </div>

          <div className='unscheduled-section'>
            <AppRightSidebarCalendar width={SIDEBAR_WIDTH_UNSCHEDULED} title={'Unscheduled Work Orders'}>
              <SidebarUnscheduledWorkOrders />
            </AppRightSidebarCalendar>
          </div>
        </div>
      </div>

      <div className='sidebar-section'>
        <RightPanelContainer width={rightSideBarWidth} />
      </div>
    </PageWrapper>
  )
}

export default Sentry.withProfiler(CalendarPage)

const PageWrapper = styled.div<{ $unscheduledWidth: number; $rightSideBarWidth: number }>`
  position: absolute;
  inset: 0;
  top: 56px;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .header-padding {
    padding: 0px 32px;
    z-index: 2;
  }
  .column-without-sidebar {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 1;
    max-width: calc(100vw - ${({ $rightSideBarWidth }) => $rightSideBarWidth}px);
    transition: max-width 300ms;
    transition-timing-function: cubic-bezier(0, 0.8, 0.1, 1);
    position: relative;

    .header-row {
      display: flex;
      flex-direction: row;
      position: relative;
      z-index: 1; /* No new stacking context */
      .header-row-main {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        .header-row-main-title {
          font-size: 24px;
        }
      }
      .header-row-unscheduled {
        display: flex;
        flex-direction: column;
        width: ${({ $unscheduledWidth }) => $unscheduledWidth}px;
        overflow: hidden;
        transition: width 300ms;
        transition-timing-function: cubic-bezier(0, 0.8, 0.1, 1);
        .header-row-unscheduled-title {
          font-size: 18px;
        }
      }
    }
    .content-row {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      z-index: 0; /* No new stacking context */
      flex: 1;

      position: relative;
      overflow: hidden;
      .main-section {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        position: relative;
        z-index: 0; /* No new stacking context */
      }
      .unscheduled-section {
        display: flex;
        flex-direction: column;
        z-index: 0; /* No new stacking context */
        width: ${({ $unscheduledWidth }) => $unscheduledWidth}px;
        overflow: hidden;
        transition: width 300ms;
        transition-timing-function: cubic-bezier(0, 0.8, 0.1, 1);
      }
    }
    .sidebar-section {
      /* display: flex; */
      /* flex-direction: column; */
      z-index: 0; /* No new stacking context */
      width: ${({ $rightSideBarWidth }) => $rightSideBarWidth}px;
      overflow: hidden;
      transition: width 300ms;
      transition-timing-function: cubic-bezier(0, 0.8, 0.1, 1);
      position: relative;
    }
  }
`
