import { useState } from 'react'

import { GetMaterialsQuery, GetTripsQuery, GetWorkAreasQuery } from '@/gql/graphql'
import useMaterialsQuery from '@/utils/queryHooks/useMaterialsQuery'
import useTripsQuery from '@/utils/queryHooks/useTripsQuery'
import useWorkAreasQuery from '@/utils/queryHooks/useWorkAreasQuery'

interface UseHandlePasteProps {
  setCustomErrorMessages: React.Dispatch<React.SetStateAction<string[]>>
  setCustomWarningMessages: React.Dispatch<React.SetStateAction<string[]>>
}

type WorkArea = GetWorkAreasQuery['workAreas'][0]

export default function useHandlePaste({ setCustomErrorMessages, setCustomWarningMessages }: UseHandlePasteProps) {
  const [pastedData, setPastedData] = useState<CsvWorkItem[]>([])
  const { workAreas } = useWorkAreasQuery()
  const { trips } = useTripsQuery()
  const { materials } = useMaterialsQuery()

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const clipboardData = event.clipboardData?.getData('text/plain')
    console.log('clipboardData', clipboardData)

    // Split the clipboard data into rows and columns
    const rows = clipboardData?.split('\n').map(row => row.split('\t'))
    setCustomErrorMessages([])
    setCustomWarningMessages([])

    const workItems: CsvWorkItem[] = []
    if (rows && rows.length > 0) {
      if (rows[0].length !== 18) setCustomErrorMessages(prev => [...prev, `Expected 18 columns, but found ${rows[0].length}.`])

      rows.forEach((row, rowIndex) => {
        const workItem: CsvWorkItem = {
          workArea: row[0],
          itemCode: row[1],
          displayName: row[2],
          grouping: row[3],
          trip: row[4],
          quantity: row[5],
          unitOfMeasure: row[6],
          needed: row[7],
          costPerUnit: row[8],
          materialCost: row[9],
          laborCostPerUnit: row[10],
          laborCost: row[11],
          totalCost: row[12],
          markUp: row[13],
          pricePerUnit: row[14],
          totalPrice: row[15],
          hiddenOnQuote: row[16],
          hiddenOnWorkOrders: row[17],
        }
        // if totalPrice is not a number greater than zero, we can skip this one
        if (
          workItem.totalPrice &&
          !isNaN(parseCurrencyStringToNumber(workItem.totalPrice)) &&
          parseCurrencyStringToNumber(workItem.totalPrice) <= 0
        )
          return

        // check if itemCode trimmed has a space in it
        if (workItem.itemCode.trim().includes(' '))
          setCustomErrorMessages(prev => [...prev, `Row #${rowIndex + 1}: Item code contains a space.`])

        if (!workItem.trip || workItem.trip.trim() === '')
          setCustomErrorMessages(prev => [...prev, `Row #${rowIndex + 1}: Trip is required.`])

        const { errors, warnings } = validateWorkItemNumbers(workItem, rowIndex + 1, workAreas, materials, trips)
        setCustomErrorMessages(prev => [...prev, ...errors])
        setCustomWarningMessages(prev => [...prev, ...warnings])
        workItems.push(workItem)
      })
    }
    setPastedData(workItems)
  }
  return { handlePaste, pastedData }
}

function parseCurrencyStringToNumber(currencyString: string): number {
  return Number(currencyString.replace(/[^0-9.-]+/g, ''))
}

interface CsvWorkItem {
  workArea: string
  itemCode: string
  displayName: string
  grouping: string
  trip: string
  quantity: string
  unitOfMeasure: string
  needed: string
  costPerUnit: string
  materialCost: string
  laborCostPerUnit: string
  laborCost: string
  totalCost: string
  markUp: string
  pricePerUnit: string
  totalPrice: string
  hiddenOnQuote: string
  hiddenOnWorkOrders: string
}

function workAreaExists(workArea: string, workAreas: WorkArea[]): boolean {
  return workAreas.some(area => area.name === workArea)
}

function materialExists(itemCode: string, materials: GetMaterialsQuery['materials']): boolean {
  return materials.some(material => material.itemCode === itemCode)
}

function tripExists(trip: string, trips: GetTripsQuery['trips']): boolean {
  return trips.some(item => item.name === trip)
}

const EXISTING_UNITS_OF_MEASURE = ['usd', 'sqft', 'each', 'linft']

function validateWorkItemNumbers(
  workItem: CsvWorkItem,
  rowNumber: number,
  workAreas: WorkArea[],
  materials: GetMaterialsQuery['materials'],
  trips: GetTripsQuery['trips']
): { errors: string[]; warnings: string[] } {
  const errors: string[] = []
  const warnings: string[] = []
  const totalPrice =
    workItem.totalPrice && !isNaN(parseCurrencyStringToNumber(workItem.totalPrice))
      ? parseCurrencyStringToNumber(workItem.totalPrice)
      : null
  const quantity =
    workItem.quantity && !isNaN(parseCurrencyStringToNumber(workItem.quantity)) ? parseCurrencyStringToNumber(workItem.quantity) : null
  const laborCost =
    workItem.laborCost && !isNaN(parseCurrencyStringToNumber(workItem.laborCost)) ? parseCurrencyStringToNumber(workItem.laborCost) : null
  const materialCost =
    workItem.materialCost && !isNaN(parseCurrencyStringToNumber(workItem.materialCost))
      ? parseCurrencyStringToNumber(workItem.materialCost)
      : null
  const needed =
    workItem.needed && !isNaN(parseCurrencyStringToNumber(workItem.needed)) ? parseCurrencyStringToNumber(workItem.needed) : null
  const costPerUnit =
    workItem.costPerUnit && !isNaN(parseCurrencyStringToNumber(workItem.costPerUnit))
      ? parseCurrencyStringToNumber(workItem.costPerUnit)
      : null
  const laborCostPerUnit =
    workItem.laborCostPerUnit && !isNaN(parseCurrencyStringToNumber(workItem.laborCostPerUnit))
      ? parseCurrencyStringToNumber(workItem.laborCostPerUnit)
      : null
  // if totalPrice is a valid number greater than 0, quantity is required to be a valid number greater than zero
  if (totalPrice !== null && totalPrice > 0) {
    if (quantity === null || quantity <= 0) {
      errors.push(`Row #${rowNumber}: Quantity must be greater than zero if total price is greater than zero.`)
    }
  }
  // if laborCost is a valid number greater than 0, laborCostPerUnit is required to be a valid number greater than zero
  if (laborCost !== null && laborCost > 0) {
    if (laborCostPerUnit === null || laborCostPerUnit <= 0) {
      errors.push(`Row #${rowNumber}: Labor cost per unit must be greater than zero if labor cost is greater than zero.`)
    }
  }
  // if materialCost is a valid number greater than 0, costPerUnit is required to be a valid number greater than zero
  if (materialCost !== null && materialCost > 0) {
    if (costPerUnit === null || costPerUnit <= 0) {
      errors.push(`Row #${rowNumber}: Cost per unit must be greater than zero if material cost is greater than zero.`)
    }
  }
  let isExistingMaterial = false
  // warn if the workArea entered is not in the list of work areas
  if (workItem.workArea) {
    if (!workAreaExists(workItem.workArea, workAreas)) {
      warnings.push(`Row #${rowNumber}: Work Area "${workItem.workArea}" does not exist.`)
    }
  } else {
    errors.push(`Row #${rowNumber}: Work Area is required.`)
  }
  // warn if the itemCode entered is not in the list of materials
  if (workItem.itemCode) {
    if (!materialExists(workItem.itemCode, materials)) {
      warnings.push(`Row #${rowNumber}: Item code "${workItem.itemCode}" does not exist.`)
      isExistingMaterial = true
    }
  } else {
    errors.push(`Row #${rowNumber}: Item code is required.`)
  }
  // warn if the trip entered is not in the list of trips
  if (workItem.trip && !tripExists(workItem.trip, trips)) {
    errors.push(
      `Row #${rowNumber}: Trip "${workItem.trip}" does not exist. Must select from list: ${trips.map(trip => trip.name).join(', ')}`
    )
  }
  // quantity must be a number greater than zero
  if (quantity === null || quantity <= 0) {
    errors.push(`Row #${rowNumber}: Quantity must be greater than zero.`)
  }
  // if not existing material
  if (!isExistingMaterial) {
    // error if the displayName is blank
    if (!workItem.displayName || workItem.displayName.trim() === '') {
      errors.push(`Row #${rowNumber}: Display name is required for new materials.`)
    }
    // error if the unitOfMeasure is blank
    if (!workItem.unitOfMeasure || workItem.unitOfMeasure.trim() === '') {
      errors.push(`Row #${rowNumber}: Unit of measure is required for new materials.`)
    } else if (!EXISTING_UNITS_OF_MEASURE.includes(workItem.unitOfMeasure)) {
      // warn if the unitOfMeasure is not in the list of existing units of measure
      warnings.push(`Row #${rowNumber}: Unit of measure "${workItem.unitOfMeasure}" is not a recognized unit of measure.`)
    }
    // if materialCost is a valid number greater than 0 and quantity is a valid number greater than 0, needed is required to be a valid number greater than zero
    if (materialCost !== null && materialCost > 0 && quantity !== null && quantity > 0) {
      if (needed === null || needed <= 0) {
        errors.push(`Row #${rowNumber}: Needed is required for new materials with a cost.`)
      }
    }
  }

  return { errors, warnings }
}
