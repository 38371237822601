import Select, { Theme } from 'react-select'
import styled from 'styled-components'

import { GetStaffListQuery } from '@/gql/graphql'
import useStaffListQuery from '@/utils/queryHooks/useStaffListQuery'

import useEstimateAttribute from '../hooks/useEstimateAttribute'
import useSelectColors from '../hooks/useSelectColors'
import useSubmitEstimateValues from '../hooks/useSubmitEstimateValues'

type SalesPerson = GetStaffListQuery['users'][0]
type SalesPersonOption = {
  label: string
  value: string
}

interface SalesPersonDropdownProps {
  estimateId: string
}

const UNASSIGNED_SALESPERSON_OPTION = { label: 'No Salesperson Assigned', value: null }

const SalesPersonDropdown = ({ estimateId }: SalesPersonDropdownProps) => {
  const salespersonId = useEstimateAttribute('salespersonId')

  const { staffList } = useStaffListQuery()

  const options = staffList
    .map((salesPerson: SalesPerson) => ({ label: salesPerson.fullName, value: salesPerson.id }))
    .concat(UNASSIGNED_SALESPERSON_OPTION)
  const selectedSalesperson = options.find((option: SalesPersonOption) => option.value === salespersonId)
  const colors = useSelectColors()
  const submitEstimateValues = useSubmitEstimateValues()
  const handleChange = (option: SalesPersonOption | null) => {
    console.log('Selected salesperson:', option)
    // @ts-expect-error - TODO: fix this
    if (option && option.value) submitEstimateValues({ estimateId, values: [{ attribute: 'salespersonId', value: option.value }] })
  }

  return (
    <StyledWrapper>
      <Select
        options={options}
        value={!selectedSalesperson ? UNASSIGNED_SALESPERSON_OPTION : selectedSalesperson}
        placeholder='Select Salesperson'
        inputId='salesperson-selector'
        classNamePrefix='salesperson-selector'
        onChange={handleChange}
        theme={(theme: Theme) => ({
          ...theme,
          colors,
        })}
        styles={{
          container: (base: React.CSSProperties) => ({
            ...base,
            transform: 'scale(0.86)',
            transformOrigin: 'top right',
          }),
          control: (base: React.CSSProperties) => ({
            ...base,
          }),
        }}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export default SalesPersonDropdown
