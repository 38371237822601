import { Temporal } from '@js-temporal/polyfill'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useMemo } from 'react'

import { dateStringHoveredAtom, dateStringPendingAddAtom, dbDispatchDatesAtom, workOrderPickedUpAtom } from '../atoms'
import { CalendarWorkOrderType } from '../types'

const useSelectedDateDispatches = (date: Temporal.PlainDate) => {
  const dateString = useMemo(() => date.toString(), [date])
  const dateStringHovered = useAtomValue(dateStringHoveredAtom)
  const dateStringPendingAdd = useAtomValue(dateStringPendingAddAtom)
  const workOrderPickedUp = useAtomValue(workOrderPickedUpAtom)

  const shouldSetPotential = useMemo(
    () =>
      workOrderPickedUp &&
      workOrderPickedUp.dateString !== dateString &&
      (dateStringPendingAdd === dateString || dateStringHovered === dateString),
    [workOrderPickedUp, dateString, dateStringPendingAdd, dateStringHovered]
  )

  const potential = shouldSetPotential ? [workOrderPickedUp] : ([] as CalendarWorkOrderType[])

  const selectorFunction = useCallback((all: Record<string, CalendarWorkOrderType[]>) => all[dateString] ?? [], [dateString])

  const selectedDispatchDate = useAtomValue(selectAtom(dbDispatchDatesAtom, selectorFunction))

  const filteredItems = useMemo(() => {
    if (dateString === workOrderPickedUp?.dateString) return selectedDispatchDate
    return selectedDispatchDate.filter(dispatch => dispatch.workOrderId !== workOrderPickedUp?.workOrderId)
  }, [dateString, workOrderPickedUp, selectedDispatchDate])

  return potential.concat(filteredItems)
}

export default useSelectedDateDispatches
