import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'

import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import { graphql } from '@/gql'
import { ScheduleWorkOrderMutationVariables } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

import { dateStringHoveredAtom, dateStringPendingAddAtom, dbDispatchDatesAtom, workOrderPickedUpAtom } from '../atoms'
import { CALENDAR_WORK_ORDERS_QUERY_KEY, CalendarWorkOrderType, DISPATCHES_BY_DATE_QUERY_KEY, GET_WORK_ORDER_QUERY_KEY } from '../types'

const useDragDropMonitor = (firstWeekDate: string, lastWeekDate: string) => {
  const setDateStringPendingAdd = useSetAtom(dateStringPendingAddAtom)
  const setDateStringHovered = useSetAtom(dateStringHoveredAtom)
  const setDbDispatchDates = useSetAtom(dbDispatchDatesAtom)
  const setWorkOrderPickedUp = useSetAtom(workOrderPickedUpAtom)
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { isPending: isPendingQuery, refetch: refetchCalendarDispatches } = useQuery({
    queryKey: [DISPATCHES_BY_DATE_QUERY_KEY, { firstWeekDate, lastWeekDate }],
    queryFn: async () => {
      try {
        const result = await graphQLClient.request(DISPATCHES_BY_DATE_QUERY, { firstWeekDate, lastWeekDate })
        const dispatchesByDate = result?.dispatchesByDate
        const dispatchesByDateObject: Record<string, CalendarWorkOrderType[]> = {}
        dispatchesByDate.forEach(({ date, dispatches }) => {
          dispatchesByDateObject[date] = dispatches
            .filter(d => Boolean(d.workOrder && d.workOrderId))
            .map(({ workOrderId, workOrder, ...dispatch }) => ({
              workOrderId: workOrderId ?? '',
              workOrder,
              dispatchId: dispatch.id,
              dispatch,
              dateString: date,
              estimateId: workOrder?.estimateId ?? null,
              estimateTitle: workOrder?.estimateTitle ?? null,
              jobId: workOrder?.jobId ?? null,
            }))
        })
        setDbDispatchDates(dispatchesByDateObject)
        return dispatchesByDateObject
      } catch (error) {
        throw new Error('Failed to fetch dispatches by date' + error)
      }
    },
  })

  const { mutate, isPending: isPendingMutation } = useMutation({
    mutationFn: async (variables: ScheduleWorkOrderMutationVariables) => await graphQLClient.request(SCHEDULE_WORK_ORDER, variables),
    onSuccess: (_, variables: ScheduleWorkOrderMutationVariables) => {
      console.log('Successfully scheduled work order')
      // if no dispatch ID, then it was taken from unscheduled. If no date, it was unscheduled
      if (!variables.dispatchId || !variables.date) queryClient.invalidateQueries({ queryKey: [CALENDAR_WORK_ORDERS_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [GET_WORK_ORDER_QUERY_KEY] })
      refetchCalendarDispatches().then(() => {
        setDateStringPendingAdd(null)
        setWorkOrderPickedUp(null)
      })
    },
    onError: error => console.error('Failed to schedule work order', error),
    onSettled: () => setDateStringHovered(null),
  })

  useEffect(() => {
    return monitorForElements({
      onDrop({ source, location }) {
        const destination = location.current.dropTargets[0]
        const destinationData = destination?.data
        const destinationDateString = (destinationData?.dateString ?? null) as string | null

        const sourceData = source.data

        const destinationType = (destination?.data?.type ?? null) as string | null
        const dispatchId = (sourceData?.dispatchId ?? null) as string | null
        const workOrderId = (sourceData?.workOrderId ?? null) as string | null
        const sourceDateString = (source.data?.dateString ?? null) as string | null

        console.log('onDrop data: ', {
          sourceData,
          destinationData,
          destinationDateString,
          destinationType,
          dispatchId,
          workOrderId,
          sourceDateString,
        })

        const variables: ScheduleWorkOrderMutationVariables = {
          workOrderId: workOrderId ?? '',
          dispatchId,
          date: destinationDateString,
        }
        if (destinationDateString === null) {
          // if dropped outside of any drop targets
          console.warn('Dropped outside of any date targets')
          setDateStringHovered(null)

          if (destinationType === 'UNSCHEDULE') {
            console.warn('Un-scheduling: ', variables)
            mutate(variables)
          }
          return
        }
        if (sourceDateString === destinationDateString) {
          console.warn('Dropped on same date')
          setDateStringHovered(null)
          setWorkOrderPickedUp(null)
          return
        }

        setDateStringPendingAdd(destinationDateString)

        if (!workOrderId) return setDateStringPendingAdd(null)

        console.log('Scheduling work order with variables: ', variables)
        mutate(variables)

        console.log(`Dropped workOrderId: ${workOrderId} on date: ${destinationDateString}`)
      },
    })
  }, [mutate, setWorkOrderPickedUp, setDateStringPendingAdd, setDateStringHovered])

  return { isPendingMutation, isPendingQuery, mutate }
}

export default useDragDropMonitor

const SCHEDULE_WORK_ORDER = graphql(/* GraphQL */ `
  mutation ScheduleWorkOrder($workOrderId: ID!, $dispatchId: UUID, $date: Date) {
    scheduleWorkOrder(workOrderId: $workOrderId, dispatchId: $dispatchId, date: $date) {
      success
      message
    }
  }
`)

const DISPATCHES_BY_DATE_QUERY = graphql(/* GraphQL */ `
  query GetDispatchesByDate($firstWeekDate: String!, $lastWeekDate: String!) {
    dispatchesByDate(firstWeekDate: $firstWeekDate, lastWeekDate: $lastWeekDate) {
      date
      dispatches {
        id
        date
        workOrderId
        workOrder {
          id
          name
          status
          statusNotes
          deferScheduleDate
          nextDispatchDate
          tripName
          projectSiteAddress
          customerName
          estimateId
          jobId
          jobTitle
          isConfirmed
          isWorkCompleted
          estimateTitle
        }
      }
    }
  }
`)
