import { Row } from '@tanstack/react-table'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import Checkbox from '@atlaskit/checkbox'

import { editorSelectionStateAtom } from '../editorAtoms'
import { QueriedEstimateWorkItem } from '../editorTypes'

interface SelectRowCellProps {
  row: Row<QueriedEstimateWorkItem>
}

const useIsSelected = (workItemId: string | null) => {
  const selectedWorkItemSelectedStateAtom = useMemo(
    () => selectAtom(editorSelectionStateAtom, state => (workItemId === null ? false : state[workItemId])),
    [workItemId]
  )
  const isSelected = useAtomValue(selectedWorkItemSelectedStateAtom)
  return isSelected
}

const SelectRowCell = ({ row }: SelectRowCellProps) => {
  const isChecked = useIsSelected(row?.original?.id ?? null)
  const isIndeterminate = row?.getIsSomeSelected() ?? false
  const isDisabled = row?.getCanSelect() !== true
  const onChange =
    row?.getToggleSelectedHandler() ??
    (() => {
      console.log('no handler')
    })

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Checkbox isChecked={isChecked} isIndeterminate={isIndeterminate} isDisabled={isDisabled} onChange={onChange} size='large' />
    </div>
  )
}

export default SelectRowCell
