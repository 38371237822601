import { Row } from '@tanstack/react-table'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

import { editingCellAtom } from '../editorAtoms'
import { QueriedEstimateWorkItem, WorkItemEditableAttributeKeys } from '../editorTypes'

import TableCell from './TableCell'

const useIsRowEditing = (row: Row<QueriedEstimateWorkItem>) => {
  const editingCell = useAtomValue(editingCellAtom)
  return useMemo(() => row.id === editingCell?.rowId, [row, editingCell])
}

interface TableRowProps {
  row: Row<QueriedEstimateWorkItem>
  rowIndex: number
  isLastRow: boolean
  previousRowWorkItemId: string
  nextRowWorkItemId: string
}

const TableRow = ({ row, rowIndex, isLastRow, previousRowWorkItemId, nextRowWorkItemId }: TableRowProps) => {
  const isRowEditing = useIsRowEditing(row)
  const isFirstRow = rowIndex === 0
  return (
    <tr key={row.id} className={`tr ${isRowEditing ? 'editing' : ''}`}>
      {row.getVisibleCells().map((cell, index) => (
        <TableCell
          key={cell.id}
          cell={cell}
          row={row}
          isFirstRow={isFirstRow}
          isLastRow={isLastRow}
          nextRowWorkItemId={nextRowWorkItemId}
          previousRowWorkItemId={previousRowWorkItemId}
          currentColumnIndex={index}
          columnAttribute={cell.column.columnDef.id as WorkItemEditableAttributeKeys}
        />
      ))}
    </tr>
  )
}

export default TableRow
