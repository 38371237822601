import { Temporal } from '@js-temporal/polyfill'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import AppPage from '@/components/AppPage'

import {
  DRAGGABLE_DISPATCH_SIDEBAR_WIDTH,
  DRAGGABLE_DISPATCH_SIDEBAR_WIDTH_SINGLE,
  INSTALLER_SIDEBAR_WIDTH,
  VIEW_MODE_BY_VEHICLE,
} from '../dispatchConstants'
import { ViewMode } from '../dispatchTypes'

import AvailableVehicles from './components/AvailableVehicles'
import DispatchPageActions from './components/DispatchPageActions'
import { isDispatchSidebarExpandedAtom } from './components/SidebarDispatchActions'

import useDispatchDateQuery from './hooks/useDispatchDateQuery'
import useDispatchDragDropMonitor from './hooks/useDispatchDragDropMonitor'
import useWatchAtoms from './hooks/useWatchAtoms'

import AvailableDispatches from './SidebarDispatches'
import DispatchInstallerList from './SidebarInstallers'

const DispatchPage = () => {
  const params = useParams()
  const isDispatchSidebarExpanded = useAtomValue(isDispatchSidebarExpandedAtom)
  const { isToday, dateString, fullDateString, selectedDate, viewMode } = useMemo(() => {
    const today = Temporal.PlainDate.from(Temporal.Now.plainDateISO())
    const selectedDate = params.dateString ? Temporal.PlainDate.from(params.dateString) : today
    const isToday = selectedDate.equals(today)
    const dateString = selectedDate.toString()
    const fullDateString = isToday ? 'Today' : getFullDateString(selectedDate)
    return { isToday, dateString, fullDateString, selectedDate, viewMode: (params.viewMode ?? VIEW_MODE_BY_VEHICLE) as ViewMode }
  }, [params])

  useDispatchDateQuery(dateString)
  useDispatchDragDropMonitor({ dateString })
  useWatchAtoms()
  return (
    <AppPage
      disableScroll
      header={'Dispatch for ' + fullDateString}
      actions={<DispatchPageActions selectedDate={selectedDate} isToday={isToday} viewMode={viewMode} />}
    >
      <StyledDispatchPage $isDispatchSidebarExpanded={isDispatchSidebarExpanded}>
        <div className='left-column'>{dateString && <AvailableDispatches dateString={dateString} />}</div>

        <div className='center-column'>
          <AvailableVehicles dateString={dateString} />
        </div>

        <div className='right-column'>
          <DispatchInstallerList />
        </div>
      </StyledDispatchPage>
    </AppPage>
  )
}

export default DispatchPage

function getFullDateString(date: Temporal.PlainDate) {
  return date
    .toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    .replace(/(\d+)(?=(st|nd|rd|th))/, '$1$2')
}

interface StyledDispatchPageProps {
  $isDispatchSidebarExpanded: boolean
}
const StyledDispatchPage = styled.div<StyledDispatchPageProps>`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-top: 1px solid ${token('color.border')};

  .left-column {
    border-right: 1px solid ${token('color.border')};
    position: relative;
    width: ${props => (props.$isDispatchSidebarExpanded ? DRAGGABLE_DISPATCH_SIDEBAR_WIDTH : DRAGGABLE_DISPATCH_SIDEBAR_WIDTH_SINGLE)}px;
    transition: width 300ms;
    transition-timing-function: cubic-bezier(0, 0.8, 0.1, 1);
  }
  .center-column {
    /* background: ${token('elevation.surface.sunken')}; */
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
  }
  .right-column {
    width: ${INSTALLER_SIDEBAR_WIDTH}px;
    border-left: 1px solid ${token('color.border')};
    position: relative;
  }
`
