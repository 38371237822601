import { useCallback, useState } from 'react'

import Button from '@atlaskit/button/new'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import VehicleCreatorForm from './VehicleCreatorForm'

const VehicleCreatorModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen])
  const openModal = useCallback(() => setIsOpen(true), [setIsOpen])
  return (
    <>
      <Button onClick={openModal}>Add Vehicle</Button>

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <VehicleCreatorForm closeModal={closeModal} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}

export default VehicleCreatorModal
