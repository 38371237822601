import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { vehicleDispatchesByIdAtom } from '../../dispatchTypes'

const useSelectedVehicleDispatch = (vehicleDispatchId: string, dateString: string) => {
  const vehicleDispatchAtom = useMemo(
    () =>
      selectAtom(vehicleDispatchesByIdAtom, state => {
        const vehicleDispatch = state[vehicleDispatchId]
        if (!vehicleDispatch) return null
        if (!dateString) return vehicleDispatch
        if (vehicleDispatch['filterDate'] === dateString) return vehicleDispatch
        return null
      }),
    [vehicleDispatchId, dateString]
  )
  return useAtomValue(vehicleDispatchAtom)
}

export default useSelectedVehicleDispatch
