import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { IconButton } from '@atlaskit/button/new'
import EditorCollapseIcon from '@atlaskit/icon/glyph/editor/collapse'
import EditorExpandIcon from '@atlaskit/icon/glyph/editor/expand'
import { token } from '@atlaskit/tokens'

export const isDispatchSidebarExpandedAtom = atomWithStorage<boolean>('isDispatchSidebarExpanded', true, undefined, { getOnInit: true })

const SidebarDispatchesActions = () => {
  const [isExpanded, setIsExpanded] = useAtom(isDispatchSidebarExpandedAtom)

  return (
    <IconButton
      icon={iconProps =>
        isExpanded ? (
          <EditorCollapseIcon {...iconProps} label='Collapse' primaryColor={token('color.icon.subtlest')} />
        ) : (
          <EditorExpandIcon {...iconProps} label='Expand' primaryColor={token('color.icon.subtlest')} />
        )
      }
      label='Edit'
      onClick={() => setIsExpanded(prev => !prev)}
      appearance='subtle'
    />
  )
}

export default SidebarDispatchesActions
