import { AnimatePresence, motion, Transition } from 'framer-motion'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

interface HoveredDropZoneProps {
  isHovered: boolean
  hoveredText: string
  hoveredTextLineTwo: string
  transition?: Transition
  fontSize?: number
  alreadyExists?: boolean
  inset?: number | string
  fillContainer?: boolean
}
const HOVERED_TRANSITION = { duration: 0.2, ease: [0.0, 0.99, 0.33, 0.99] }

const HoveredDropZone = ({
  isHovered,
  hoveredText,
  hoveredTextLineTwo,
  transition = HOVERED_TRANSITION,
  fontSize = 18,
  inset = 8,
  alreadyExists = false,
  fillContainer = false,
}: HoveredDropZoneProps) => (
  <AnimatePresence>
    {isHovered && (
      <HoveredDiv
        initial={{ opacity: 0.0, scale: fillContainer ? 1 : 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={transition}
        exit={{ opacity: 0.0, scale: fillContainer ? 1 : 0.9 }}
        style={{ inset }}
        $alreadyExists={alreadyExists}
        $fillContainer={fillContainer}
      >
        <h3 style={{ fontSize }}>
          {hoveredText}
          {!hoveredTextLineTwo ? null : (
            <>
              <br />
              {hoveredTextLineTwo}
            </>
          )}
        </h3>
      </HoveredDiv>
    )}
  </AnimatePresence>
)

export default HoveredDropZone

interface HoveredDivProps {
  $alreadyExists: boolean
  $fillContainer: boolean
}
const HoveredDiv = styled(motion.div)<HoveredDivProps>`
  position: absolute;
  inset: ${({ $fillContainer }) => ($fillContainer ? '0px' : '8px')};

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid ${({ $alreadyExists }) => ($alreadyExists ? token('color.border') : token('color.border.selected'))};
  background-color: ${({ $alreadyExists }) =>
    $alreadyExists ? token('color.background.neutral.hovered') : token('color.blanket.selected')};

  border-radius: ${({ $fillContainer }) => ($fillContainer ? '0px' : '4px')};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 99;

  > h3 {
    color: ${({ $alreadyExists }) => ($alreadyExists ? token('color.text.subtlest') : token('color.text.brand'))};
    font-weight: 700;
    text-align: center;
  }
`
