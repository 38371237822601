import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { IconButton } from '@atlaskit/button/new'
import Link from '@atlaskit/link'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'

import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler'
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down'
import HipchatChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up'

import useIsModifierPressed from '@/utils/useIsModifierPressed'
import { CardColorOptions, TRIP_COLORS } from '@/utils/utilities'

import useIsDragging from '../hooks/useIsDragging'
import useSelectedDispatch from '../selectors/useSelectedDispatch'
import useSelectedVehicleDispatch from '../selectors/useSelectedVehicleDispatch'

import { VEHICLE_DISPATCH_CARD_GAP, VEHICLE_DISPATCH_CARD_HEIGHT, VEHICLE_DISPATCH_LEFT_COL_WIDTH } from '../../dispatchConstants'
import { DragSourceData, itemPickedUpAtom } from '../../dispatchTypes'

import VehicleDispatchInstallerSection from './VehicleDispatchInstallerSection'

interface VehicleDispatchContainerProps {
  vehicleDispatchId: string
  dateString: string
  vehicleId: string
  moveOrder: ({ currentIndex, newIndex }: { currentIndex: number; newIndex: number }) => void
  currentIndex: number
  isLast?: boolean
}

const VehicleDispatchContainer = ({
  vehicleDispatchId,
  dateString,
  vehicleId,
  moveOrder,
  currentIndex,
  isLast = false,
}: VehicleDispatchContainerProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const setItemPickedUp = useSetAtom(itemPickedUpAtom)

  const vehicleDispatch = useSelectedVehicleDispatch(vehicleDispatchId, dateString)
  const dispatchId = vehicleDispatch?.dispatchId ?? null
  const dispatch = useSelectedDispatch(dispatchId)
  const assignedVehicleIds = useMemo(() => dispatch?.assignedVehicleIds ?? [], [dispatch])

  const assignedInstallerIds = vehicleDispatch?.assignedInstallerIds ?? []

  const workOrder = dispatch?.workOrder ?? null
  const tripName = workOrder?.tripName ?? 'No Trip Name'
  const accentColor: CardColorOptions = TRIP_COLORS?.[tripName] ?? 'orange'
  const address = workOrder?.projectSiteAddress ?? 'No Job Site Address'

  const draggableItem: DragSourceData = useMemo(
    () => ({
      dispatchId,
      vehicleId,
      assignedVehicleIds,
      itemIdentifiedVariant: 'DISPATCH',
    }),
    [dispatchId, vehicleId, assignedVehicleIds]
  )
  const getInitialData = useCallback(() => draggableItem, [draggableItem])
  const onDragStart = useCallback(() => setItemPickedUp(draggableItem), [draggableItem, setItemPickedUp])
  const onDrop = useCallback(() => setItemPickedUp(prev => (prev.dispatchId !== dispatchId ? prev : {})), [dispatchId, setItemPickedUp])

  useEffect(() => {
    if (ref.current) return draggable({ element: ref.current, getInitialData, onDragStart, onDrop })
  }, [getInitialData, onDragStart, onDrop])

  const { isDragging } = useIsDragging(draggableItem)
  const isHoldingOptionKey = useIsModifierPressed('alt')

  const handleMoveUp = useCallback(() => moveOrder({ currentIndex, newIndex: currentIndex - 1 }), [moveOrder, currentIndex])
  const handleMoveDown = useCallback(() => moveOrder({ currentIndex, newIndex: currentIndex + 1 }), [moveOrder, currentIndex])

  if (!dispatchId) return null

  return (
    <StyledVehicleDispatchContainer
      // layoutScroll
      $currentIndex={currentIndex}
      $accentColor={accentColor}
      $isDragging={isDragging}
      $isHoldingOptionKey={isHoldingOptionKey}
    >
      <div className='vehicle-dispatch-left-column-outer'>
        <div className='vehicle-dispatch-card-left-column' ref={ref}>
          <div className='vehicle-dispatch-card-left-column-drag-handle'>
            {isHoldingOptionKey ? (
              <div className='vehicle-dispatch-alt-arrows'>
                <IconButton icon={HipchatChevronUpIcon} label='Move Up' onClick={handleMoveUp} isDisabled={currentIndex === 0} />
                <IconButton icon={HipchatChevronDownIcon} label='Move Down' onClick={handleMoveDown} isDisabled={isLast} />
              </div>
            ) : null}
            <DragHandlerIcon label='Drag' size='medium' />
          </div>

          <div className='job-card-info'>
            <div className='customer-name'>{workOrder?.customerName ?? 'No Customer Name'}</div>

            <div className='job-site-address'>
              <Link appearance='subtle' href={`/jobs/${workOrder?.jobId}/estimates/${workOrder?.estimateId}`} target='_blank'>
                {address}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <VehicleDispatchInstallerSection
        assignedInstallerIds={assignedInstallerIds}
        vehicleDispatchId={vehicleDispatchId}
        vehicleId={vehicleId}
      />
    </StyledVehicleDispatchContainer>
  )
}

export default VehicleDispatchContainer

interface StyledVehicleDispatchContainerProps {
  $accentColor: CardColorOptions
  $isDragging: boolean
  $isHoldingOptionKey: boolean
  $currentIndex: number
}
const StyledVehicleDispatchContainer = styled.div<StyledVehicleDispatchContainerProps>`
  position: absolute;
  left: 12px;
  right: 12px;
  top: 0;
  transform: translateY(
    ${({ $currentIndex }) => $currentIndex * (VEHICLE_DISPATCH_CARD_HEIGHT + VEHICLE_DISPATCH_CARD_GAP) + VEHICLE_DISPATCH_CARD_GAP * 0.5}px
  );
  transition: transform 400ms cubic-bezier(0.2, 0.8, 0.2, 1);
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  flex: 1 1 auto;

  height: ${VEHICLE_DISPATCH_CARD_HEIGHT + VEHICLE_DISPATCH_CARD_GAP}px;
  box-sizing: border-box;

  /* border: 1px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)}; */

  div {
    position: relative;
    box-sizing: border-box;
  }

  .vehicle-dispatch-left-column-outer {
    width: ${VEHICLE_DISPATCH_LEFT_COL_WIDTH}px;
    height: ${VEHICLE_DISPATCH_CARD_HEIGHT + VEHICLE_DISPATCH_CARD_GAP}px;
    min-width: ${VEHICLE_DISPATCH_LEFT_COL_WIDTH}px;
    /* overflow: hidden; */
    display: flex;
    flex-direction: row;
    align-items: center;

    .vehicle-dispatch-card-left-column {
      border: 1px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
      border-left-width: 2px;
      border-bottom-width: 2.5px;
      border-top-width: 0.5px;
      border-radius: 6px 0 0 6px;

      background: ${({ $accentColor }) =>
        token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtle`)};

      display: flex;
      flex: 0 0 none;
      flex-direction: row;
      align-items: center;

      height: ${VEHICLE_DISPATCH_CARD_HEIGHT}px;
      width: ${VEHICLE_DISPATCH_LEFT_COL_WIDTH}px;
      min-width: ${VEHICLE_DISPATCH_LEFT_COL_WIDTH}px;
      overflow: hidden;

      > div {
        opacity: ${({ $isDragging }) => ($isDragging ? `0.5` : `1`)};
      }

      .vehicle-dispatch-card-left-column-drag-handle {
        margin: 0 4px;
        border-radius: 4px;
        opacity: ${({ $isHoldingOptionKey }) => ($isHoldingOptionKey ? `1` : `0.2`)};

        display: flex;
        justify-content: center;
        align-items: center;

        height: 40px;
        width: 36px;

        flex: 0 0 auto;
        transition:
          background 0.1s,
          opacity 0.1s;
        &:hover {
          cursor: grab;
          opacity: 1;
          background: ${token('color.background.neutral.subtle.hovered')};
        }
        > div {
          z-index: 1;
        }

        .vehicle-dispatch-alt-arrows {
          > button {
            height: 24px;
            margin: 0;
            width: 100%;
            overflow: hidden;
          }
          background: ${({ $accentColor }) =>
            token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtle`)};
          position: absolute;
          top: -4px;
          left: 0;
          right: 0;
          bottom: -4px;
          z-index: 2;
        }
      }

      .job-card-info {
        flex: 1 1 auto;
        padding: 4px 8px 2px 0px;
        overflow: hidden;

        .customer-name {
          padding-bottom: 4px;
          border-bottom: 1px solid ${token('color.border')};
          padding-left: 1px;
          font-weight: bold;
          font-size: 13px;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .job-site-address {
          display: flex;
          flex-direction: column;
          font-size: 11px;
          padding: 4px 2px;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          a,
          span {
            color: ${token('color.text')};
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .vehicle-dispatch-card-right-column {
    border: 1px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
    border-right-width: 0.5px;
    border-bottom-width: 2px;
    border-top-width: 0.5px;
  }
`
