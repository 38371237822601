const TruckIcon = ({ color = '#ccc', size = 24 }: { color: string; size?: number }) => {
  return (
    <div style={{ width: size, height: size }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        style={{ transform: 'translateY(1px) scaleX(-1)', color: color }}
      >
        <rect x='1' y='5' width='15' height='11'></rect>
        <polygon points='16 8 20 8 23 11 23 16 16 16 16 8'></polygon>
        <circle cx='5.5' cy='18.5' r='2.5'></circle>
        <circle cx='18.5' cy='18.5' r='2.5'></circle>
      </svg>
    </div>
  )
}

export default TruckIcon
