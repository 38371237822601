import { Cell, flexRender, Row } from '@tanstack/react-table'
import { useAtom, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { editingCellAtom } from '../editorAtoms'
import { getCommonPinningStyles } from '../editorConstants'
import { QueriedEstimateWorkItem, WorkItemEditableAttributeKeys } from '../editorTypes'

import NumberCell from './NumberCell'
import NumberFieldEditMode from './NumberFieldEditMode'
import TextCell from './TextCell'

interface TableCellProps {
  cell: Cell<QueriedEstimateWorkItem, unknown>
  isFirstRow: boolean
  isLastRow: boolean
  row: Row<QueriedEstimateWorkItem>
  nextRowWorkItemId: string
  previousRowWorkItemId: string
  currentColumnIndex: number
  columnAttribute: WorkItemEditableAttributeKeys
}

const useIsEditing = (cell: Cell<QueriedEstimateWorkItem, unknown>) => {
  const [editingCell] = useAtom(editingCellAtom)
  const isEditing = useMemo(() => editingCell.rowId === cell.row.id && editingCell.columnId === cell.column.id, [editingCell, cell])
  return isEditing
}

const ViewMode = ({ cell }: { cell: Cell<QueriedEstimateWorkItem, unknown> }) => {
  const meta = cell.column.columnDef.meta ?? {}
  const stringSelector = meta?.stringSelector ?? null
  const valueString = stringSelector ? stringSelector(cell.row.original) : `${cell.getValue() ?? null}`
  const decimalPlaces = meta?.decimalPlaces
  const prefix = meta?.prefix
  const monospace = meta?.monospace ?? false
  const suffix = meta?.suffix
  const alwaysEditable = meta?.alwaysEditable === true

  if (alwaysEditable) return flexRender(cell.column.columnDef.cell, cell.getContext())

  if (decimalPlaces === undefined) return <TextCell value={valueString} monospace={monospace} />

  return (
    // @ts-expect-error - value is unknown
    <NumberCell value={cell.getValue()} prefix={prefix} suffix={suffix} decimalPlaces={decimalPlaces} />
  )
}

const TableCell = ({ cell, columnAttribute, row }: TableCellProps) => {
  const setEditingCell = useSetAtom(editingCellAtom)
  const meta = cell.column.columnDef.meta ?? {}
  const isFirstRightPinnedColumn = cell.column.getIsPinned() === 'right' && cell.column.getIsFirstColumn('right')

  const isEditing = useIsEditing(cell)
  const isDragHandle = cell.column.id === 'dragHandle'
  // const isWorkCompleted = row.original.workOrder?.isWorkCompleted === true
  const isWorkCompleted = false
  const canEdit = meta?.canEdit === true && !isWorkCompleted
  const isNumeric = meta?.decimalPlaces !== undefined
  const width = cell.column.getSize()
  const handleClickToEdit = () => {
    setEditingCell(prev => ({ ...(prev ?? {}), rowId: row.id, columnId: cell.column.id, edits: prev.edits ?? {} }))
  }

  return (
    <td
      key={cell.id}
      className={'td' + (isFirstRightPinnedColumn ? ' first-right-pinned' : '') + (isDragHandle ? ' drag-handle' : '')}
      style={{
        ...getCommonPinningStyles(cell.column),
        width,
      }}
    >
      {!isEditing ? (
        canEdit ? (
          <EditWrapper onClick={handleClickToEdit}>
            <ViewMode cell={cell} />
          </EditWrapper>
        ) : (
          <ViewMode cell={cell} />
        )
      ) : isNumeric ? (
        <NumberFieldEditMode workItemId={row.id} attribute={columnAttribute as keyof QueriedEstimateWorkItem} />
      ) : (
        flexRender(cell.column.columnDef.cell, cell.getContext())
      )}
    </td>
  )
}

export default TableCell

const EditWrapper = styled.div`
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${token('color.background.input.hovered')};
  }
`
