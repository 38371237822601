import { format } from 'date-fns'

export function formatDateTimeString(dateString?: string | null): string | null {
  if (!dateString) return null
  // make sure it's a string
  if (typeof dateString !== 'string') return null
  // make sure it's a valid date
  const date = new Date(dateString)
  return format(date, 'yyyy-MM-dd HH:mm')
}

export function formatPhoneNumberString(value: string): string {
  // return nothing if no value
  if (!value) return ''

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length

  // returns: "x", "xx", "xxx"
  if (cvLength < 4) return currentValue

  // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

  // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
}

type CellNumberStringFromValueFunction = (value: number | null | undefined, decimalPlaces?: number, prefix?: string) => string

export const cellNumberStringFromValue: CellNumberStringFromValueFunction = (value, decimalPlaces = 0, prefix) => {
  if (value === null || value === undefined) return '-'
  let parsedValue = value
  if (typeof value !== 'number') parsedValue = parseFloat(value)
  return (
    (prefix ?? '') +
    parsedValue.toLocaleString('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
  )
}

export function formatCurrencyString(value: number | null | undefined, decimalPlaces = 2, prefix = '$'): string {
  if (value === null || value === undefined) return '-'
  let parsedValue = value
  if (typeof value !== 'number') parsedValue = parseFloat(value)
  return (
    prefix +
    parsedValue.toLocaleString('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
  )
}

export const COLOR_OPTIONS = ['magenta', 'gray', 'blue', 'red', 'yellow', 'green', 'orange', 'shelving'] as CardColorOptions[]
export type CardColorOptions = 'magenta' | 'gray' | 'blue' | 'red' | 'yellow' | 'green' | 'red' | 'yellow' | 'green' | 'orange'
export const TRIP_COLORS: Record<string, CardColorOptions> = {
  'Spray Foam': 'magenta',
  'Prep': 'gray',
  'Batt': 'blue',
  'Blow': 'red',
  'BIBS': 'yellow',
  'Shelving': 'green',
}

export const onlyMondaysFilter = (date: string) => new Date(date).getDay() !== 0
