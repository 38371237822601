import { useQuery } from '@tanstack/react-query'
import type { TableProps } from 'antd'
import { ConfigProvider, Table } from 'antd'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import useAntdTableTheme from '@/utils/useAntdTableTheme'
import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'
import { GetWorkOrderToPrintQuery } from '@/gql/graphql'

type WorkOrderType = GetWorkOrderToPrintQuery['workOrder']
type WorkItemType = WorkOrderType['workItems'][0]

const WorkOrderView = () => {
  const params = useParams()
  const workOrderId = params?.workOrderId ?? ''
  const dateString = params?.dateString ?? null
  const graphqlClient = useGraphQLClient()
  const theme = useAntdTableTheme(true)

  const { data, status } = useQuery({
    queryKey: [GET_WORK_ORDER_TO_PRINT_QUERY_KEY, workOrderId],
    queryFn: async () => graphqlClient.request(GET_WORK_ORDER_TO_PRINT, { workOrderId }),
  })
  const workOrder = data?.workOrder ?? null
  const items = (workOrder?.workItems ?? []).filter((item: WorkItemType) => item.isVisibleOnWorkOrder)

  const notesForPrint = workOrder?.estimate?.job?.notesForPrint ?? ''
  const workOrderNotes = workOrder?.estimate?.job?.customer?.workOrderNotes ?? ''

  const dispatch = !dateString ? null : (workOrder?.dispatches ?? []).find(dispatch => dispatch.date === dateString)
  const assignedVehicles = dispatch?.vehicleAssignments ?? []
  return (
    <ConfigProvider theme={theme}>
      <title>
        Work Order #{workOrder?.id}-{workOrder?.name} - Thermal Shop Admin
      </title>
      <PageWrapper>
        <PrintableWrapper>
          <div className='main-row-header'>
            <div className='logo-title-column'>
              <div className='title-info-column'>
                <div className='work-order-category'>Work Order</div>

                <h2 className='work-order-title'>
                  #{workOrder?.id}-{workOrder?.name}
                  {!!dateString ? ' - ' + dateString : ''}
                </h2>

                {!!dispatch && dispatch.installerAssignments.length > 0 && (
                  <div className='installers-section'>
                    <b>Crew:</b>
                    {dispatch.installerAssignments.map(ia => ia.installer.fullName).join(', ')}
                  </div>
                )}
              </div>
            </div>

            <div className='sheet-properties-column'>
              <div className='attribute-column'>
                {!!dateString && <div className='attribute'>Dispatch Date</div>}

                <div className='attribute'>Trip</div>

                {assignedVehicles.length === 1 && <div className='attribute'>Truck</div>}

                {assignedVehicles.length !== 1 && <div className='attribute'>Truck Count</div>}
              </div>

              <div className='values-column'>
                {dateString && <div className='value'>{dateString}</div>}

                <div className='value'>{workOrder?.tripName ?? '-'}</div>

                {assignedVehicles.length === 1 && <div className='value'>{assignedVehicles[0].vehicle.name}</div>}

                {assignedVehicles.length !== 1 && <div className='value'>{assignedVehicles.length}</div>}
              </div>
            </div>
          </div>

          {assignedVehicles.length > 1 && (
            <div className='main-row-vehicles'>
              {assignedVehicles.map((va, index) => (
                <div className='vehicle-column' style={{ marginRight: index !== assignedVehicles.length - 1 ? '8px' : undefined }}>
                  <div className='vehicle-title'>
                    Truck {index + 1} of {assignedVehicles.length}:{va.vehicle.name}
                  </div>
                  {/* <div className='vehicle-text'>
                    Job # {va?.vehicleJobIndex ?? '-'} of
                    {va?.vehicleJobCount ?? '-'}
                  </div> */}
                  <div className='vehicle-text'>
                    <b>Crew:</b> {va.installerAssignments.map(ia => ia.installer.fullName).join(', ')}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className='main-row-subheader'>
            <div className='subheader-column'>
              <div className='subheader-title'>Job Site Address</div>
              <div className='subheader-text'>{workOrder?.estimate?.job?.projectSite?.name ?? '-'}</div>
            </div>
            <div className='subheader-column'>
              <div className='subheader-title'>Project</div>
              <div className='subheader-text'>{workOrder?.estimate?.title ?? '-'}</div>
            </div>
            <div className='subheader-column'>
              <div className='subheader-title'>Customer</div>
              <div className='subheader-text'>{workOrder?.estimate?.job?.customer?.name ?? '-'}</div>
            </div>
          </div>

          {notesForPrint !== '' ? (
            <div className='main-row-notes'>
              <div className='subheader-title'>Job Notes</div>
              <div className='notes-text'>{notesForPrint}</div>
            </div>
          ) : null}

          {workOrderNotes !== '' ? (
            <div className='main-row-notes'>
              <div className='subheader-title'>Builder Notes</div>
              <div className='notes-text'>{workOrderNotes}</div>
            </div>
          ) : null}

          <Table<WorkItemType>
            rowKey='id'
            columns={loadSheetColumns}
            dataSource={items}
            size='small'
            loading={status === 'pending'}
            pagination={false}
            rowClassName={(item, index) => {
              let className = index % 2 === 1 ? 'alt-row-style' : 'main-row-style'
              if (item.materialName.toLowerCase().includes('total')) {
                className = className + ' total-row-style'
              }
              return className
            }}
          />
        </PrintableWrapper>
      </PageWrapper>
    </ConfigProvider>
  )
}

export default WorkOrderView

const loadSheetColumns: TableProps<WorkItemType>['columns'] = [
  {
    key: 'workAreaName',
    dataIndex: 'workAreaName',
    title: 'Work Area',
  },
  {
    key: 'materialName',
    dataIndex: 'materialName',
    title: 'Material',
  },
  {
    key: 'itemCode',
    dataIndex: 'itemCode',
    title: 'SKU',
  },
  {
    key: 'quantity',
    dataIndex: 'quantity',
    title: 'Qty',
    align: 'right',
  },
  {
    key: 'unitOfMeasure',
    dataIndex: 'unitOfMeasure',
    title: 'Unit',
  },
  {
    key: 'needed',
    dataIndex: 'containersNeededFinal',
    title: 'Needed',
    align: 'right',
  },
  {
    key: 'containerLabel',
    dataIndex: 'containerLabel',
    title: 'Cont',
  },
  {
    key: 'used',
    title: 'Used',
    width: 80,
    align: 'right',
    className: 'border-column',
  },
]

const GET_WORK_ORDER_TO_PRINT_QUERY_KEY = 'workOrderByIdNum'
const GET_WORK_ORDER_TO_PRINT = graphql(/* GraphQL */ `
  query GetWorkOrderToPrint($workOrderId: ID!) {
    workOrder(pk: $workOrderId) {
      id
      name
      status
      deferScheduleDate
      statusNotes
      materialCostTotal
      laborCostTotal
      totalCostTotal
      totalPrice
      marginPercent
      nextDispatchDate
      isConfirmed
      isWorkCompleted
      tripName
      dispatches {
        id
        date
        installerAssignments {
          installer {
            id
            fullName
          }
        }
        vehicleAssignments {
          vehicle {
            name
            id
          }
          installerAssignments {
            installerId
            installer {
              id
              fullName
            }
          }
        }
      }
      estimateId
      estimate {
        title
        job {
          title
          notesForPrint
          customerId
          customer {
            name
            workOrderNotes
          }
          projectSiteId
          projectSite {
            name
            placeId
            place {
              displayName
              formattedAddress
            }
          }
        }
      }
      workItems {
        estimateId
        id
        quantity
        containersNeededFinal
        isVisibleOnQuote
        isVisibleOnWorkOrder
        workAreaName
        tripName
        materialName
        itemCode
        containerLabel
        containersNeededFinal
        unitOfMeasure
      }
    }
  }
`)

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  color: #000;
  background-color: #fff;
  overflow: auto;
`
const PrintableWrapper = styled.div`
  flex: 1;
  /* border: 3px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: stretch;

  position: relative;
  padding: 24px;
  max-width: 800px;
  background-color: #fff;

  .ant-table-cell {
    color: #000;
    padding-top: 4px;
    padding-bottom: 4px;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    white-space: nowrap;
  }
  .alt-row-style {
    background-color: #f0f0f0;
  }
  .main-row-style {
    background-color: #fff;
  }
  .total-row-style {
    font-weight: 700;
  }
  td.border-column {
    border-right: 1px solid ${token('color.border')};
  }

  .work-order-category {
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 4px;
    text-transform: uppercase;
    color: #999;
  }

  .logo-title-column {
    display: flex;
    flex: 1 1 auto;
  }

  .logo-wrapper {
    flex: 0 0 auto;
    width: 72px;
    height: 72px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-info-column {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .work-order-title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .installers-section {
    margin-bottom: 4px;
    font-size: 13px;
  }

  .sheet-properties-column {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 0 0 auto;
    width: 180px;
  }

  .attribute-column,
  .values-column {
    position: relative;
    display: flex;
    flex-direction: column;
    /* gap: 4px; */
    margin-left: 8px;
    flex: 0 0 auto;
    overflow: hidden;
  }

  .attribute {
    font-weight: 600;
    text-align: right;
    margin-bottom: 4px;
  }

  .value {
    text-align: left;
    margin-bottom: 4px;
  }
  .main-row-header {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .main-row-vehicles {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 0;
    margin-bottom: 8px;
  }

  .vehicle-column {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 6px 8px;
    border: 1px solid #999;
    border-radius: 4px;
  }

  .vehicle-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .vehicle-text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
  }

  .main-row-subheader {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 0 4px;
    margin-bottom: 4px;
  }

  .subheader-column {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: 200px;
    margin-right: 8px;
  }

  .subheader-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .subheader-text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  .main-row-notes {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: 0 4px;
    margin-bottom: 8px;
  }

  .notes-text {
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    min-width: 100%;
  }
`
