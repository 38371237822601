import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useEffect, useMemo } from 'react'

import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import type { Vehicle } from '@/utils/queryHooks/useVehiclesQuery'

import useSelectedVehicleDispatchIds from '../selectors/useSelectedVehicleDispatchIds'

import { DragSourceData, hoveredDropZoneAtom, itemPickedUpAtom } from '../../dispatchTypes'

interface UseDropZoneVehicleContainerHeaderProps {
  vehicle: Vehicle
  vehicleHeaderRef: React.RefObject<HTMLDivElement | null>
}

const useDropZoneVehicleContainerHeader = ({ vehicle, vehicleHeaderRef }: UseDropZoneVehicleContainerHeaderProps) => {
  const setItemHovered = useSetAtom(hoveredDropZoneAtom)
  const vehicleId = vehicle.id
  const { vehicleDispatchIds: assignedVehicleDispatchIds } = useSelectedVehicleDispatchIds(vehicleId)

  const onDragLeave = useCallback(() => setItemHovered(prev => (prev.vehicleId === vehicleId ? {} : prev)), [vehicleId, setItemHovered])

  const dropZoneData = useMemo(() => ({ vehicleId, assignedVehicleDispatchIds }), [vehicleId, assignedVehicleDispatchIds])

  useEffect(() => {
    const dropTargetElement = vehicleHeaderRef.current
    if (!dropTargetElement) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: dropTargetElement,
      getData: () => ({ zone: 'VEHICLE_HEADER', ...dropZoneData }),
      onDragEnter: () => setItemHovered({ zone: 'VEHICLE_HEADER', ...dropZoneData }),
      onDragLeave,
      canDrop: ({ source }) => {
        const { installerId } = (source.data ?? {}) as DragSourceData
        if (!installerId) return false
        return true
      },
    })
  }, [dropZoneData, onDragLeave, setItemHovered, vehicleHeaderRef])
}

export default useDropZoneVehicleContainerHeader

export const useIsVehicleHeaderHoveredByInstaller = (vehicleId: string) => {
  const isHoveredAtom = useMemo(
    () => selectAtom(hoveredDropZoneAtom, itemHovered => itemHovered?.vehicleId === vehicleId && itemHovered?.zone === 'VEHICLE_HEADER'),
    [vehicleId]
  )
  const isInstallerPickedUpAtom = useMemo(() => selectAtom(itemPickedUpAtom, itemPickedUp => !!itemPickedUp?.installerId), [])
  const isInstallerPickedUp = useAtomValue(isInstallerPickedUpAtom)
  return useAtomValue(isHoveredAtom) && isInstallerPickedUp
}
