import { motion } from 'framer-motion'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

type AppRightSidebarProps = {
  title?: string
  width?: number
  children?: React.ReactNode
  actions?: React.ReactNode
}

const AppRightSidebar = ({ title = 'Right Sidebar Title', width = 300, actions, children }: AppRightSidebarProps) => (
  <RightSidebar width={width}>
    <RightSidebarPadding>
      <HeaderSection>
        <BreadCrumbsRow />
        <ActionsRow>{actions}</ActionsRow>
      </HeaderSection>

      <RightPanelWrapper>
        <RideSideHeader>{title}</RideSideHeader>

        <RightSideContent>{children ?? 'Content'}</RightSideContent>
      </RightPanelWrapper>
    </RightSidebarPadding>
  </RightSidebar>
)

const RightSidebar = styled(motion.div)<{ width: number }>`
  /* border-left: 1px solid red; */
  width: ${({ width }) => width}px;
  transition: width 0.12s ease-in;
  max-height: calc(100vh - 56px);
  overflow: hidden;
  position: relative;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`
const RightSidebarPadding = styled.div`
  padding-right: 20px;
  padding-left: 8px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  overflow: hidden;
`
const ActionsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-end;
  height: 36px;
`

const HeaderSection = styled.div`
  height: 60px;
  margin-top: 24px;
  margin-bottom: 16px;
`
const BreadCrumbsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
`
const RightPanelWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  min-width: 200px;
  min-height: 200px;
  max-height: 100%;
`
const RideSideHeader = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid ${token('color.border')};
  padding: 11px 9pt;
  border-bottom-width: 0px;
  font-weight: 600;
`
const RightSideContent = styled.div`
  padding: 11px 9pt;
  border: 1px solid ${token('color.border')};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export default AppRightSidebar
