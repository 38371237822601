import { useQuery } from '@tanstack/react-query'
import { AnimatePresence } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { graphql } from '@/gql'
import useGraphQLClient from '@/utils/useAuthRequest'

import { isLoadSheetVisibleAtom } from '../../dispatchTypes'

import LoadSheetTable from './LoadSheetTable'

interface LoadSheetSectionProps {
  vehicleId: string
  dateString: string
}

const LoadSheetSection = ({ vehicleId, dateString }: LoadSheetSectionProps) => {
  const graphQLClient = useGraphQLClient()

  const isVisibleAtom = useMemo(() => selectAtom(isLoadSheetVisibleAtom, state => state?.[vehicleId] ?? false), [vehicleId])
  const isLoadSheetVisible = useAtomValue(isVisibleAtom)

  const { data, error, isPending } = useQuery({
    queryKey: [GET_CALCULATED_LOAD_SHEET_QUERY_KEY, dateString, vehicleId],
    queryFn: async () => {
      const result = await graphQLClient.request(GET_CALCULATED_LOAD_SHEET, { date: dateString, vehicleId })
      if (!result || result.calculatedLoadSheetItems === undefined) throw new Error('Get Calculated Load Sheet Query Failed')
      return result.calculatedLoadSheetItems
    },
    enabled: isLoadSheetVisible,
  })

  if (!data) return null

  return (
    <AnimatePresence>
      {isLoadSheetVisible && data.length > 0 ? <LoadSheetTable data={data} error={error} isPending={isPending} /> : null}
    </AnimatePresence>
  )
}

export default LoadSheetSection

export const GET_CALCULATED_LOAD_SHEET_QUERY_KEY = 'GetCalculatedLoadSheet'
const GET_CALCULATED_LOAD_SHEET = graphql(/* GraphQL */ `
  query GetCalculatedLoadSheet($date: Date!, $vehicleId: UUID!) {
    calculatedLoadSheetItems(date: $date, vehicleId: $vehicleId) {
      materialName
      materialContainerLabel
      materialUnitOfMeasure
      itemCode
      quantityCalledFor
      unitQuantity
    }
  }
`)
