import styled from 'styled-components'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import { CELL_PADDING_HORIZONTAL } from '../editorConstants'

interface TextCellProps {
  value: string | null
  nullPlaceholder?: string
  monospace?: boolean
  noWrap?: boolean
  readOnly?: boolean
  align?: 'left' | 'right' | 'center'
  padding?: string
}

const TextCell = ({
  value,
  nullPlaceholder = '-',
  monospace = false,
  noWrap = true,
  readOnly = false,
  align = 'left',
  padding,
}: TextCellProps) => (
  <TextCellWrapper $monospace={monospace} $noWrap={noWrap} $readOnly={readOnly} $align={align} $padding={padding}>
    <span>{value ?? nullPlaceholder}</span>
  </TextCellWrapper>
)

export default TextCell

const TextCellWrapper = styled.div<{
  $monospace: boolean
  $noWrap: boolean
  $readOnly: boolean
  $align: 'left' | 'right' | 'center'
  $padding?: string
}>`
  padding: ${props => props.$padding ?? `0 ${CELL_PADDING_HORIZONTAL}px`};
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  justify-content: ${props => (props.$align === 'left' ? 'flex-start' : props.$align === 'right' ? 'flex-end' : 'center')};

  span {
    font-family: ${props => (props.$monospace ? MONOSPACE_FONT_FAMILY : 'inherit')};
    ${props => (props.$noWrap ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } : {})}
    opacity: ${props => (props.$readOnly ? 0.6 : 1)};
    text-align: ${props => props.$align};
  }
`
