import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

interface AppRightSidebarAttributeRowProps {
  label: string
  value: string | number | React.ReactNode | null
  mono?: boolean
}
const AppRightSidebarAttributeRow = ({ label, value, mono = false }: AppRightSidebarAttributeRowProps) => {
  const isNull = value === null
  return (
    <div style={{ display: 'block' }}>
      <AttributeRow>
        <AttributeLabelContainer>
          <AttributeLabel>{label}</AttributeLabel>
        </AttributeLabelContainer>

        <ValueContainer>
          <ValuePresentation>
            <ValueSpan $isNull={isNull} $mono={mono}>
              {isNull ? 'N/A' : value}
            </ValueSpan>
          </ValuePresentation>
        </ValueContainer>
      </AttributeRow>
    </div>
  )
}

export default AppRightSidebarAttributeRow

const AttributeRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 12px;
  word-break: break-word;
  text-decoration-skip-ink: auto;
`
const AttributeLabelContainer = styled.div`
  box-sizing: border-box;
  display: block;
  flex-grow: 1;
  line-height: 1;
  max-width: 170px;
  min-width: 90pt;
  padding-right: 24px;
  padding-top: 8px;
  position: relative;
  width: 40%;
`
const AttributeLabel = styled.h2`
  box-sizing: border-box;
  color: ${token('color.text.subtle', '#5E6C84')};
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: 1.3333333333333333;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
`
const ValueContainer = styled.div`
  width: 60%;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
`
const ValuePresentation = styled.div`
  border-radius: 3px;
  border: 2px solid transparent;
  box-sizing: border-box;
  display: inline-block;
  flex: 1 1 auto;
  line-height: 1;
  margin: 0;
  min-height: 32px;
  padding: 7px 0;
  width: 100%;
  word-break: break-word;
`

type ValueSpanProps = {
  $isNull: boolean
  $mono: boolean
}
const ValueSpan = styled.span<ValueSpanProps>`
  display: inline-flex;
  line-height: 1;
  font-size: 14px;
  position: relative;
  opacity: ${props => (props.$isNull ? 0.4 : 1)};
  ${({ $mono }) => ($mono === true ? `font-family: ${MONOSPACE_FONT_FAMILY};` : '')}
`
