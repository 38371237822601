import { useQuery } from '@tanstack/react-query'

import { graphql } from '@/gql'
import { GetMaterialsQuery } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

type Material = GetMaterialsQuery['materials'][0]

const useMaterialsQuery = () => {
  const graphQLClient = useGraphQLClient()

  const { data, error, status } = useQuery({
    queryKey: [GET_MATERIALS_QUERY_KEY],
    queryFn: () => graphQLClient.request(GET_MATERIALS),
    refetchInterval: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  })

  const materials = (data?.materials ?? []) as Material[]
  return { materials, error, status }
}

export default useMaterialsQuery

export const GET_MATERIALS_QUERY_KEY = 'materials'
const GET_MATERIALS = graphql(/* GraphQL */ `
  query GetMaterials {
    materials {
      id
      name
      itemCode
      unitOfMeasure
      laborCostPerUnit
      activeCostSchedule {
        id
        effectiveDate
        costPerUnit
        costPerContainer
        purchaseContainerLabel
        purchaseContainerUnitQuantity
      }
    }
  }
`)
