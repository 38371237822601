import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useMemo } from 'react'

import { databaseEstimateAtom } from '../editorAtoms'
import useEstimateAttribute from './useEstimateAttribute'
import useSubmitEstimateValues from './useSubmitEstimateValues'

type UseEstimateSortOrderResult = {
  sortOrder: string[]
  setSortOrder: (newSortOrder: string[]) => void
}

const isSameArray = (prev: string[], next: string[]) =>
  prev.length === next.length && prev.every((workItemId: string, index: number) => workItemId === next[index])

const useEstimateSortOrder = (): UseEstimateSortOrderResult => {
  const setEstimate = useSetAtom(databaseEstimateAtom)
  const sortOrderAtom = useMemo(
    () => selectAtom(databaseEstimateAtom, estimate => estimate?.sortOrder ?? ([] as string[]), isSameArray),
    []
  )
  const estimateId = useEstimateAttribute('id')
  const sortOrder = useAtomValue(sortOrderAtom)
  const submitEstimateValues = useSubmitEstimateValues() // this is a react-query mutation function

  const setSortOrder = useCallback(
    (newSortOrder: string[]) => {
      setEstimate(prev => {
        if (!prev) return null
        return { ...prev, sortOrder: newSortOrder }
      })
      submitEstimateValues({
        estimateId,
        // @ts-expect-error: JSON type is allowed here
        values: [{ attribute: 'sortOrder', value: newSortOrder }],
      })
    },
    [estimateId, setEstimate, submitEstimateValues]
  )

  return { sortOrder, setSortOrder }
}

export default useEstimateSortOrder
