import { flexRender } from '@tanstack/react-table'
import { useAtom } from 'jotai'
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import useTableState from './hooks/useTableState'

import TableRow from './components/TableRow'

import AddNewRow from './components/AddNewRow'
import { columnSizingAtom } from './editorAtoms'
import {
  CELL_PADDING_HORIZONTAL,
  CELL_PADDING_VERTICAL,
  COLUMN_WIDTH_DRAG,
  COLUMN_WIDTH_SELECT,
  getCommonPinningStyles,
  ROW_HEIGHT_OUTER,
} from './editorConstants'
import { EditorColumnKey } from './editorTypes'

const EditorTable = () => {
  const tableRef = useRef<HTMLTableElement>(null)
  const [columnSizing, setColumnSizing] = useAtom(columnSizingAtom)

  const { getFlatHeaders, getRowModel, getFooterGroups } = useTableState()

  const headers = getFlatHeaders()
  const rows = getRowModel().rows
  const rowsCount = rows.length

  const updateColumnWidths = useCallback(() => {
    const columnsCount = headers.length
    if (tableRef.current && rowsCount > 0) {
      const newWidths = Array.from(tableRef.current.querySelectorAll('th')).map(th => th.offsetWidth)
      setColumnSizing(prev => {
        const keys = Object.keys(prev)
        if (keys.length === columnsCount) return prev

        const newWidthsObj = {} as Record<EditorColumnKey, number>
        newWidths.forEach((width, index) => {
          const columnKey = headers[index].id as EditorColumnKey
          newWidthsObj[columnKey] = columnKey === 'dragHandle' ? COLUMN_WIDTH_DRAG : columnKey === 'select' ? COLUMN_WIDTH_SELECT : width
        })
        return newWidthsObj
      })
    }
  }, [rowsCount, setColumnSizing, headers])

  React.useEffect(() => updateColumnWidths(), [rowsCount, updateColumnWidths])

  return (
    <TableWrapper>
      <TableScrollContainer>
        <div className='inner-wrapper'>
          <StyledTable ref={tableRef}>
            <thead className='thead'>
              <tr className='tr'>
                {headers.map(header => {
                  const width = header.getSize()
                  const stateWidth = columnSizing[header.id as EditorColumnKey]

                  return (
                    <th
                      className='th'
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ ...getCommonPinningStyles(header.column), width: stateWidth ?? width }}
                    >
                      <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>

                      {header.column.getCanResize() && (
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                          }}
                        />
                      )}
                    </th>
                  )
                })}
              </tr>
            </thead>

            <tbody className='tbody'>
              {rows.map((row, rowIndex) => {
                const isLastRow = rowIndex === rowsCount - 1
                const previousRowWorkItemId = rowIndex === 0 ? null : rows[rowIndex - 1].original.id
                const nextRowWorkItemId = isLastRow ? null : rows[rowIndex + 1].original.id
                return (
                  <TableRow
                    key={row.id}
                    row={row}
                    rowIndex={rowIndex}
                    isLastRow={isLastRow}
                    previousRowWorkItemId={previousRowWorkItemId}
                    nextRowWorkItemId={nextRowWorkItemId}
                  />
                )
              })}

              <AddNewRow headers={headers} />
            </tbody>

            <tfoot className='tfoot'>
              {getFooterGroups().map(footerGroup => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map(footer => {
                    const isFirstRightPinnedColumn = footer.column.getIsPinned() === 'right' && footer.column.getIsFirstColumn('right')
                    return (
                      <td
                        className={'td' + (isFirstRightPinnedColumn ? ' first-right-pinned' : '')}
                        key={footer.id}
                        style={{ ...getCommonPinningStyles(footer.column) }}
                      >
                        {flexRender(footer.column.columnDef.footer, footer.getContext())}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tfoot>
          </StyledTable>
        </div>
      </TableScrollContainer>
    </TableWrapper>
  )
}

export default EditorTable

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  left: 32px;
  right: 32px;
  bottom: 8px;
  overflow: hidden;
  align-items: stretch;

  .info-header {
    padding: 12px 24px;
    border: 1px solid ${token('color.border')};
    border-radius: 4px;
    flex: 0 0 auto;
    margin-right: 32px;
    margin-bottom: 8px;
  }
`

const TableScrollContainer = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;

  .inner-wrapper {
    overflow: auto;
    /* flex: 1 1 0%; */
    max-height: 100%;
    border: 1px solid red;
    border: 1px solid ${token('color.border')};
    border-bottom: 2px solid ${token('color.border')};
    border-radius: 4px;
    /* padding-bottom: 20px; // Add space for the scrollbar */
    /* Webkit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      height: 20px; /* Set the height of the scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1; /* Color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background: red; /* Color of the scroll thumb */
      border-radius: 10px; /* Rounded corners on the thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555; /* Color of the scroll thumb on hover */
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    /* For Internet Explorer and Edge */
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  width: fit-content;
  table-layout: fixed;

  .resizer {
    position: absolute;
    top: 0;
    height: 100%;
    right: -2px;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    background: blue;
    opacity: 1;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  // all cells
  .td,
  .th {
    border-right-width: 1px;
    position: relative;
    height: 100%;
    box-sizing: border-box;

    &.first-right-pinned {
      border-left: 2px solid ${token('color.border')};
    }

    &.drag-handle {
      border-right: 1px solid transparent;
    }
  }

  // header row wrapper
  .thead {
    position: sticky;
    top: 0;
    z-index: 4;
    background-color: ${token('elevation.surface')};
    border-bottom: 2px solid ${token('color.border')};

    // header row cells
    .th {
      padding: ${CELL_PADDING_VERTICAL}px ${CELL_PADDING_HORIZONTAL}px;
      background-color: ${token('elevation.surface')};
      vertical-align: bottom;
      color: ${token('color.text.subtlest')};
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      border-bottom: 2px solid ${token('color.border')};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .tbody {
    border-left: 1px solid ${token('color.border')};
    margin-bottom: 44px;

    // body row
    .tr {
      /* height: ${ROW_HEIGHT_OUTER}px; */
      z-index: 0;
      box-sizing: border-box;

      // body cells
      .td {
        padding: 0px;
        background-color: ${token('elevation.surface')};
        border-right: 1px solid ${token('color.border')};
        border-bottom: 1px solid ${token('color.border')};
        height: ${ROW_HEIGHT_OUTER}px;
        white-space: nowrap;
        /* overflow: hidden;
        text-overflow: ellipsis; */

        .drag-handle {
          cursor: grab;
          text-align: center;
          vertical-align: center;
        }
      }

      .extra-row-cell {
        border-top: 2px solid ${token('color.border')};
        background-color: ${token('elevation.surface.sunken')};

        button {
          padding-inline-start: 11px;
          > span {
            text-align: left;
          }
        }
      }

      &.editing {
        z-index: 5;
      }
    }
  }

  // footer styling
  .tfoot {
    position: sticky;
    bottom: 0px;
    z-index: 3; /* Adjust as necessary to ensure it stays above table body */
    background-color: ${token('elevation.surface.raised')}; /* Ensure it stands out */

    .td {
      font-weight: bold;
      text-align: right;
      border-top: 2px solid ${token('color.border')};

      padding-top: 8px;
      padding-bottom: 16px;
      border-right: 1px solid ${token('color.border')};
      border-bottom: none; /* No border needed at bottom */
      background-color: ${token('elevation.surface.raised')}; /* Ensure it stands out */

      &.first-right-pinned {
        border-left: 2px solid ${token('color.border')} !important;
      }
    }
  }
`
