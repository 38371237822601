import { token } from '@atlaskit/tokens'

import { Column } from '@tanstack/react-table'
import { CSSProperties } from 'react'
import { Props as SelectProps } from 'react-select'
import { QueriedEstimateWorkItem } from './editorTypes'

export const ROW_HEIGHT_OUTER = 40

export const COLUMN_WIDTH_DRAG = 30
export const COLUMN_WIDTH_SELECT = 40

export const CELL_PADDING_HORIZONTAL = 10
export const CELL_PADDING_VERTICAL = 4

const INDICATOR_COUNT = 2
const INDICATOR_SPACING = 2
export const COLUMN_WIDTH_INDICATOR = CELL_PADDING_HORIZONTAL * 2 + 28 * INDICATOR_COUNT + INDICATOR_SPACING * (INDICATOR_COUNT - 1)

export const UNIVERSAL_EDITOR_SELECT_PROPS: Partial<SelectProps> = {
  menuPortalTarget: document.body,
  menuShouldScrollIntoView: false,
  menuPlacement: 'auto',
  tabSelectsValue: true,
  closeMenuOnScroll: true,
  menuShouldBlockScroll: true,
  openMenuOnFocus: true,
  autoFocus: true,
  styles: {
    container: (base: React.CSSProperties) => ({
      ...base,
      height: '100%',
    }),
    control: (base: React.CSSProperties) => {
      return {
        ...base,
        'height': 'calc(100% - 2px)',
        'border': 'none',
        'backgroundColor': 'inherit',
        '&:hover': {
          backgroundColor: token('color.background.selected'),
        },
      }
    },
    menu: (base: React.CSSProperties) => ({
      ...base,
      zIndex: 9999,
    }),
    menuPortal: (base: React.CSSProperties) => ({
      ...base,
      zIndex: 9999,
    }),
  },
}

export const getCommonPinningStyles = (column: Column<QueriedEstimateWorkItem>): CSSProperties => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left')
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right')
  const start = column.getStart('left')
  const isSelect = column.id === 'select'
  const style = {
    // boxShadow: isLastLeftPinnedColumn ? '4px 0 4px 0px gray' : isFirstRightPinnedColumn ? '4px 0 4px -4px gray' : undefined,
    borderRightWidth: isLastLeftPinnedColumn ? '2px' : isPinned ? '0px' : '1px',
    borderLeftWidth: isFirstRightPinnedColumn ? '2px' : undefined,
    left: isPinned === 'left' ? `${isSelect ? start : start}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    // opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: isPinned ? 2 : 0,
    // backdropFilter: 'blur(10px)',
  } as CSSProperties

  return style
}
