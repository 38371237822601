import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { ModifierKeysPressed, modifierKeysPressedAtom } from '@/utils/useWatchModifierKeys'

const useIsModifierPressed = (modifier: keyof ModifierKeysPressed) => {
  const modifierPressedAtom = useMemo(() => selectAtom(modifierKeysPressedAtom, keysPressed => keysPressed[modifier]), [modifier])
  return useAtomValue(modifierPressedAtom)
}

export default useIsModifierPressed
