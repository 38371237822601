import { Ref } from 'react'
import styled from 'styled-components'

import Button, { IconButton } from '@atlaskit/button/new'
import { token } from '@atlaskit/tokens'

import CrossIcon from '@atlaskit/icon/glyph/cross'

type AppRightPanelProps = {
  handleClose: () => void
  isPanelOpen: boolean
  panelWidth: number
  title: string
  children: React.ReactNode
  topBarLeftActions?: React.ReactNode
  topBarRightActions?: React.ReactNode
  ref?: Ref<HTMLDivElement>
}

const MainAction = <Button>Add Item</Button>

const AppRightPanel = ({
  handleClose,
  ref,
  isPanelOpen,
  panelWidth = 400,
  title = 'Right Panel Title',
  topBarLeftActions,
  topBarRightActions,
  children,
}: AppRightPanelProps) => {
  return (
    <RightPanel $isOpen={isPanelOpen} $panelWidth={panelWidth} ref={ref ?? undefined}>
      <TopBar>
        <TopBarLeft>{topBarLeftActions ?? MainAction}</TopBarLeft>

        <TopBarRight>
          {topBarRightActions}
          <IconButton appearance='subtle' icon={CrossIcon} onClick={handleClose} label='Close' />
        </TopBarRight>
      </TopBar>

      <MainSection>
        <HeaderTextWrapper>
          <HeaderText>{title}</HeaderText>
        </HeaderTextWrapper>

        <MainContent>{children}</MainContent>
      </MainSection>
    </RightPanel>
  )
}

export default AppRightPanel

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding-right: 24px;
  border-bottom: 1px solid ${token('color.border')};
`
const HeaderTextWrapper = styled.div`
  margin-bottom: 8px;
  margin-top: 12px;
  justify-content: start;
  display: flex;
  flex-direction: row;
  align-items: start;
  /* flex-grow: 1; */
`
const TopBarLeft = styled.div`
  flex-grow: 1;
  min-width: 1px;
  flex-shrink: 0;
  height: 56px;
  margin-right: 8px;
  overflow-y: hidden;
  transition: flex-shrink 0ms 200ms;
  /* border: 1px solid blue; */
  align-items: center;
  display: flex;
  padding-left: 24px;
`
const TopBarRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 1px solid red; */
  flex-shrink: 0;
  min-width: 1px;
`
const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  min-height: 0;
  padding-bottom: 6px;
  padding-top: 6px;
`
const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 24px 24px 24px;
  position: relative;
  overflow: auto;
`
const MainContent = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;
`
type RightPanelProps = {
  $isOpen: boolean
  $panelWidth: number
}

const RightPanel = styled.div<RightPanelProps>`
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  /* flex: 0; */
  width: ${({ $panelWidth }) => $panelWidth}px;
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: translateX(${({ $isOpen }) => ($isOpen ? '-100%' : '0')});
  position: absolute;
  bottom: 0;
  top: 0;
  /* right: 0; */
  left: 100%;
  background: ${token('elevation.surface.overlay')};
  border-left: 1px solid ${token('color.border')};
  z-index: 200;
  overflow: hidden;
  /* backdrop-filter: blur(12px); // This be the blur */
  /* overflow-y: scroll; */
`
