import { useMutation, useQueryClient } from '@tanstack/react-query'

import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'
import { GET_ESTIMATE_QUERY_KEY } from './useEditorData'

import { SubmitEstimateValuesVariables } from '../editorTypes'

const useSubmitEstimateValues = () => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationFn: async (variables: SubmitEstimateValuesVariables) => {
      console.log('Submitting estimate values:', variables)
      const response = await graphQLClient.request(SUBMIT_ESTIMATE_VALUES, {
        estimateId: variables.estimateId,
        values: variables.values,
      })
      if (!response.submitEstimateValues.success) throw new Error(response.submitEstimateValues.message)
      return response
    },
    onSuccess: (data, variables) => {
      console.log('Successfully submitted estimate values:', { variables, data })
      queryClient.invalidateQueries({ queryKey: [GET_ESTIMATE_QUERY_KEY, { estimateId: variables.estimateId }] })
    },
    onError: (error, variables) => {
      console.error('Error submitting estimate values:', { variables, error })
    },
    onSettled: (data, error, variables) => {
      console.log('Settled submitting estimate values:', { variables, data, error })
    },
  })
  return mutate
}

export default useSubmitEstimateValues

const SUBMIT_ESTIMATE_VALUES = graphql(/* GraphQL */ `
  mutation SubmitEstimateValues($estimateId: UUID!, $values: [AttributeValueInput!]!) {
    submitEstimateValues(estimateId: $estimateId, values: $values) {
      success
      message
    }
  }
`)
