import { useMutation, useQueryClient } from '@tanstack/react-query'

import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'
import { SubmitJobValueMutationVariables } from '@/gql/graphql'

interface UseSubmitJobValueProps {
  refetch?: () => void
  queryKeysToInvalidate?: string[]
}
export const useSubmitJobValue = ({ refetch, queryKeysToInvalidate }: UseSubmitJobValueProps) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async (variables: SubmitJobValueMutationVariables) => {
      console.log('Submitting Job Value Mutation: ', variables)
      const response = await graphQLClient.request(SUBMIT_JOB_VALUE, variables)
      if (!response.submitJobValue.success) throw new Error(response.submitJobValue.message)
      return response
    },
    onSuccess: () => {
      if (refetch) refetch()
      if (queryKeysToInvalidate) queryClient.invalidateQueries({ queryKey: queryKeysToInvalidate })
    },
  })

  return mutation
}

export default useSubmitJobValue

const SUBMIT_JOB_VALUE = graphql(/* GraphQL */ `
  mutation SubmitJobValue($jobId: UUID!, $attribute: String!, $value: String) {
    submitJobValue(jobId: $jobId, attribute: $attribute, value: $value) {
      success
      message
    }
  }
`)
