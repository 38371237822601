import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'

import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import { DispatchDropZone, DragDestinationData, DragSourceData, hoveredDropZoneAtom } from '../../dispatchTypes'

interface UseDropZoneVehicleContainerProps {
  vehicleId: string
  vehicleDispatchId: string
  containerRef: React.RefObject<HTMLDivElement | null>
  assignedInstallerIds: string[]
}

const zone: DispatchDropZone = 'VEHICLE_DISPATCH_INSTALLERS'

const useDropZoneVehicleInstallers = ({
  vehicleId,
  vehicleDispatchId,
  assignedInstallerIds,
  containerRef,
}: UseDropZoneVehicleContainerProps) => {
  const dropZoneData: DragDestinationData = useMemo(
    () => ({ vehicleId, vehicleDispatchId, zone, assignedInstallerIds }),
    [vehicleId, vehicleDispatchId, assignedInstallerIds]
  )
  const setItemHovered = useSetAtom(hoveredDropZoneAtom)

  const onDragLeave = useCallback(
    () => setItemHovered(prev => (prev.vehicleDispatchId !== vehicleDispatchId ? prev : {})),
    [vehicleDispatchId, setItemHovered]
  )
  const onDragEnter = useCallback(() => setItemHovered(dropZoneData), [dropZoneData, setItemHovered])

  useEffect(() => {
    const dropTargetElement = containerRef.current
    if (!dropTargetElement) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: dropTargetElement,
      getData: () => ({ ...dropZoneData }),
      onDragEnter,
      onDragLeave,
      canDrop: ({ source }) => {
        const sourceData = (source?.data ?? {}) as DragSourceData
        const installerId = sourceData.installerId
        const isInstaller = !!installerId
        const isSourceVehicleDispatchId = sourceData.vehicleDispatchId === vehicleDispatchId
        const isInstallerIdAssigned = installerId && assignedInstallerIds.includes(installerId)
        return isInstaller && !isSourceVehicleDispatchId && !isInstallerIdAssigned
      },
    })
  }, [dropZoneData, onDragEnter, onDragLeave, containerRef, vehicleDispatchId, assignedInstallerIds])
}

export default useDropZoneVehicleInstallers
