import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import AppPage from '@/components/AppPage'

import { databaseEstimateAtom } from './editorAtoms'
import { QueriedEstimate } from './editorTypes'

import useAtomCleanup from './hooks/useAtomCleanup'
import useEditorData from './hooks/useEditorData'
import useMutationQueue from './hooks/useMutationQueue'

import EditorTable from './EditorTable'
import EstimateEditorPageActions from './EstimateEditorPageActions'

const EstimateEditorPage = () => {
  useAtomCleanup()
  const estimateId = useParams().estimateId
  useEditorData(estimateId)

  const estimate = useAtomValue(databaseEstimateAtom)

  const { isSubmitting } = useMutationQueue()
  const estimateTitle = estimate?.title ?? 'Estimate Title' + (isSubmitting ? ' (Saving...)' : '')

  const { breadcrumbs, browserTitle } = useMemo(
    () => ({
      breadcrumbs: estimatePageBreadcrumbs(estimate),
      browserTitle: 'Estimate: ' + (estimate?.title ?? ''),
    }),
    [estimate]
  )

  return (
    <AppPage header={estimateTitle} breadcrumbs={breadcrumbs} disableScroll actions={<EstimateEditorPageActions />}>
      <title>{browserTitle}</title>
      <EditorTable />
    </AppPage>
  )
}

export default EstimateEditorPage

const estimatePageBreadcrumbs = (estimate: Omit<QueriedEstimate, 'workItems'> | null) => {
  const breadcrumbs = [{ navigateTo: '/jobs', label: 'Jobs' }]
  if (!estimate) return breadcrumbs
  const { jobId, job, id } = estimate

  if (jobId && job.title)
    breadcrumbs.push({
      navigateTo: `/jobs/${jobId}`,
      label: 'Job: ' + job.title,
    })

  if (id && jobId)
    breadcrumbs.push({
      navigateTo: `/jobs/${jobId}/estimates`,
      label: 'Estimates',
    })

  return breadcrumbs
}
