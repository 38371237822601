import { useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import CreateEstimateForm from './CreateEstimateForm'

export default function CreateEstimateModal({ estimateNames }: { estimateNames: string[] }) {
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useLocation()

  const jobTitle = state?.title ?? 'ERR: Job Title'

  const jobId = params?.jobId ?? null
  const isOpen = jobId !== null

  const closeModal = useCallback(() => navigate(`/jobs/${jobId}`), [jobId, navigate])

  return (
    <ModalTransition>
      {isOpen ? (
        <Modal onClose={closeModal}>
          <CreateEstimateForm estimateNames={estimateNames} closeModal={closeModal} jobTitle={jobTitle} jobId={jobId} />
        </Modal>
      ) : null}
    </ModalTransition>
  )
}
