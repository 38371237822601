import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useEffect, useMemo } from 'react'

import type { DropTargetGetFeedbackArgs, ElementDragType } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import useSelectedVehicleDispatchIds from '../selectors/useSelectedVehicleDispatchIds'

import { dispatchIdsByVehicleIdAtom, DragSourceData, hoveredDropZoneAtom, itemPickedUpAtom } from '../../dispatchTypes'

interface UseDropZoneVehicleContainerProps {
  vehicleId: string
  vehicleBodyRef: React.RefObject<HTMLDivElement | null>
}
type CanDropFn = ((args: DropTargetGetFeedbackArgs<ElementDragType>) => boolean) | undefined

const useDropZoneVehicleContainer = ({ vehicleId, vehicleBodyRef }: UseDropZoneVehicleContainerProps) => {
  const setItemHovered = useSetAtom(hoveredDropZoneAtom)

  const { vehicleDispatchIds: assignedVehicleDispatchIds } = useSelectedVehicleDispatchIds(vehicleId)
  const dropZoneData = useMemo(() => ({ vehicleId, assignedVehicleDispatchIds }), [vehicleId, assignedVehicleDispatchIds])

  const onDragLeave = useCallback(() => setItemHovered(prev => (prev.vehicleId === vehicleId ? {} : prev)), [vehicleId, setItemHovered])

  const canDropBody: CanDropFn = useCallback(
    (args: DropTargetGetFeedbackArgs<ElementDragType>) => {
      const { dispatchId, vehicleDispatchId: sourceVehicleDispatchId } = (args.source?.data ?? {}) as DragSourceData
      if (!dispatchId || (sourceVehicleDispatchId && assignedVehicleDispatchIds.includes(sourceVehicleDispatchId))) return false
      return true
    },
    [assignedVehicleDispatchIds]
  )

  useEffect(() => {
    const dropTargetElement = vehicleBodyRef.current
    if (!dropTargetElement) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: dropTargetElement,
      getData: () => ({ zone: 'VEHICLE_BODY', ...dropZoneData }),
      onDragEnter: () => setItemHovered({ zone: 'VEHICLE_BODY', ...dropZoneData }),
      onDragLeave,
      canDrop: canDropBody,
    })
  }, [dropZoneData, canDropBody, onDragLeave, setItemHovered, vehicleBodyRef])
}

export default useDropZoneVehicleContainer

export const useIsVehicleBodyHoveredByDispatch = (vehicleId: string) => {
  const isHoveredAtom = useMemo(
    () =>
      selectAtom(hoveredDropZoneAtom, itemHovered => {
        const isHovered = itemHovered?.vehicleId === vehicleId && itemHovered?.zone === 'VEHICLE_BODY'
        return isHovered
      }),
    [vehicleId]
  )
  const pickedUpDispatchIdAtom = useMemo(() => selectAtom(itemPickedUpAtom, itemPickedUp => itemPickedUp?.dispatchId ?? null), [])
  const pickedUpDispatchId = useAtomValue(pickedUpDispatchIdAtom)
  const pickedUpItemIsDispatch = pickedUpDispatchId !== null
  const pickedUpDispatchExistsInThisVehicleAtom = useMemo(
    () =>
      selectAtom(dispatchIdsByVehicleIdAtom, dispatchIdsByVehicleId => {
        const dispatchIds = dispatchIdsByVehicleId[vehicleId] ?? []
        return dispatchIds.includes(pickedUpDispatchId ?? '')
      }),
    [vehicleId, pickedUpDispatchId]
  )
  const isHovered = useAtomValue(isHoveredAtom) && pickedUpItemIsDispatch
  const alreadyExists = useAtomValue(pickedUpDispatchExistsInThisVehicleAtom)
  return { isHovered, alreadyExists }
}
