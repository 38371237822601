import { useMemo } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { Checkbox } from '@atlaskit/checkbox'
import { token } from '@atlaskit/tokens'

import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'
import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import NumberTextField from '@/components/NumberTextField'
import NumberCell from '@/pages/jobs/EstimateEditor/components/NumberCell'

type WorkOrder = GetDispatchPayoutDetailsQuery['workOrder']
type Dispatch = WorkOrder['dispatches'][number]

const CELL_PAD = '4px 8px'
export interface InstallerProperties {
  payoutAmount?: string
  hoursWorked?: string
  locked?: boolean
  isChanged?: boolean
}
export type InstallersEdits = Record<string, InstallerProperties>

interface InstallerTableProps {
  dispatch: Dispatch
  totalTargetPayout: number
  installerAmounts: InstallersEdits
  setInstallerAmounts: React.Dispatch<React.SetStateAction<InstallersEdits>>
}
const SuffixText = styled.span`
  font-weight: 500;
  color: ${token('color.text.subtlest')};
  margin-right: 8px;
  font-family: ${MONOSPACE_FONT_FAMILY};
`

const InstallerTable = ({ dispatch, totalTargetPayout, installerAmounts, setInstallerAmounts }: InstallerTableProps) => {
  const installerAssignments = dispatch.installerAssignments.sort((a, b) => a.installer.fullName.localeCompare(b.installer.fullName))

  const totalInstallerPayout = useMemo(() => {
    return installerAssignments.reduce(
      (acc, assignment) => acc + Number(installerAmounts[assignment.installerId]?.payoutAmount ?? Number(assignment.payoutAmount ?? 0)),
      0
    )
  }, [installerAssignments, installerAmounts])

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Installer</th>
          <th>Hours Worked</th>
          <th>Payout Amount</th>
          <th>Percentage</th>
          <th>Locked</th>
        </tr>
      </thead>

      <tbody>
        {installerAssignments.map(assignment => {
          const installerProps = installerAmounts[assignment.installerId]
          const payoutAmount = installerProps?.payoutAmount ?? assignment.payoutAmount ?? '0.00'
          const hours = installerProps?.hoursWorked ?? assignment.hoursWorked ?? '0.00'
          const percentage = !totalInstallerPayout ? null : ((Number(payoutAmount) / Number(totalTargetPayout)) * 100).toFixed(1)
          const installerId = assignment.installerId as string
          const isLocked = installerProps?.locked ?? false
          return (
            <tr key={assignment.id}>
              <td className='row-header'>{assignment.installer.fullName}</td>

              <td>
                <NumberTextField
                  // @ts-expect-error don't care
                  isCompact
                  value={hours}
                  elemAfterInput={<SuffixText>hrs</SuffixText>}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setInstallerAmounts(prev => ({ ...prev, [installerId]: { ...installerProps, hoursWorked: e.target.value } }))
                  }
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                    setInstallerAmounts(prev => ({
                      ...prev,
                      [installerId]: { ...installerProps, hoursWorked: Number(e.target.value).toFixed(2) },
                    }))
                  }
                  onFocus={handleFocus}
                />
              </td>

              <td>
                <NumberTextField
                  isMoney
                  // @ts-expect-error don't care
                  isCompact
                  value={payoutAmount}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setInstallerAmounts(prev => ({ ...prev, [installerId]: { ...installerProps, payoutAmount: e.target.value } }))
                  }
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                    setInstallerAmounts(prev => ({
                      ...prev,
                      [installerId]: { ...installerProps, payoutAmount: Number(e.target.value).toFixed(2) },
                    }))
                  }
                  onFocus={handleFocus}
                />
              </td>

              <td>
                <NumberCell padding={CELL_PAD} readOnly value={percentage} suffix='%' />
              </td>

              <td className='no-padding'>
                <div className='checkbox-wrapper'>
                  <Checkbox
                    isChecked={isLocked}
                    onChange={e =>
                      setInstallerAmounts(prev => ({ ...prev, [installerId]: { ...prev[installerId], locked: e.target.checked } }))
                    }
                  />
                </div>
              </td>
            </tr>
          )
        })}

        <tr>
          <td className='row-header'>Total Entered</td>

          <td>
            <NumberCell
              padding={CELL_PAD}
              readOnly
              suffix='hrs'
              value={installerAssignments.reduce(
                (acc, a) => acc + Number(installerAmounts[a.installerId]?.hoursWorked ?? a.hoursWorked),
                0
              )}
            />
          </td>

          <td>
            <NumberCell padding={CELL_PAD} readOnly value={totalInstallerPayout} prefix='$' />
          </td>

          <td colSpan={2}>
            <Button appearance='subtle' onClick={handleAutoCalculate} isDisabled={!totalTargetPayout} shouldFitContainer>
              Auto Calculate
            </Button>
          </td>
        </tr>

        {totalTargetPayout !== totalInstallerPayout && (
          <tr style={{ background: token('color.background.danger') }}>
            <td className='row-header'>Remaining</td>

            <td></td>

            <td>
              <NumberCell padding={CELL_PAD} readOnly value={totalTargetPayout - totalInstallerPayout} prefix='$' />
            </td>

            <td colSpan={2}></td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  )

  function handleAutoCalculate() {
    console.log('handleAutoCalculate')
    const lockedAmount = Object.keys(installerAmounts).reduce(
      (acc, id) =>
        acc +
        (installerAmounts[id]?.locked
          ? Number(installerAmounts[id]?.payoutAmount ?? installerAssignments.find(a => a.installerId === id)?.payoutAmount ?? 0)
          : 0),
      0
    )
    const unlockedAmount = totalTargetPayout - lockedAmount
    const unlockedInstallerAssignments = installerAssignments.filter(assignment => !installerAmounts[assignment.installerId]?.locked)
    const unlockedAmountPerInstaller = unlockedAmount / unlockedInstallerAssignments.length
    console.log({ lockedAmount, unlockedAmount, unlockedAmountPerInstaller, unlockedInstallerAssignments, installerAmounts })
    unlockedInstallerAssignments.forEach(assignment => {
      const installerId = assignment.installerId as string
      console.log(`Setting ${assignment.installer.fullName} with ID ${installerId} to ${unlockedAmountPerInstaller}`)
      setInstallerAmounts(prev => ({
        ...prev,
        [installerId]: { ...(prev?.[installerId] ?? {}), payoutAmount: unlockedAmountPerInstaller.toFixed(2) },
      }))
    })
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      e.target.select()
    }, 1) // Delay until the next event loop cycle
  }
}

export default InstallerTable

const StyledTable = styled.table`
  width: fit-content;

  td {
    padding: 0;
    font-family: ${MONOSPACE_FONT_FAMILY};
    height: 32px;
  }
  tbody > tr:nth-child(odd) {
    background-color: ${token('color.background.neutral')};
    .current-week {
      background: ${token('color.background.selected.hovered')};
    }
    td > div > div {
      &:not(:focus-within) {
        background: ${token('elevation.surface.sunken')};
      }
    }
  }
  th,
  td {
    text-align: right;

    > div > div {
      max-width: 120px;
      display: flex;
      justify-content: flex-end;
      border-radius: 0;
      border-color: ${token('color.border')};
      > span {
        color: ${token('color.text.disabled')};
      }
      input {
        /* max-width: 120px; */
        font-family: ${MONOSPACE_FONT_FAMILY};
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        -moz-appearance: textfield;
        appearance: textfield;
      }
    }
    > label {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .row-header {
    font-family: ${token('font.family.body')};
    font-weight: 500;
    padding: 0 8px;
  }
  .checkbox-wrapper {
    padding: 0 8px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    > label {
      transform-origin: center;
      transform: scale(1.33);
    }
  }
`
