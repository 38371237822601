import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'

import type { DropTargetGetFeedbackArgs, ElementDragType } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import { DragSourceData, hoveredDropZoneAtom, SidebarZone } from '../../dispatchTypes'

interface UseDropZoneSidebarsProps {
  zone: SidebarZone
  sidebarRef: React.RefObject<HTMLDivElement | null>
}

type CanDropFn = ((args: DropTargetGetFeedbackArgs<ElementDragType>) => boolean) | undefined

const useDropZoneSidebars = ({ zone, sidebarRef }: UseDropZoneSidebarsProps) => {
  const [itemHovered, setItemHovered] = useAtom(hoveredDropZoneAtom)
  const isHovered = useMemo(() => itemHovered.zone === zone, [itemHovered, zone])

  const onDragEnter = useCallback(() => setItemHovered({ zone }), [setItemHovered, zone])
  const onDragLeave = useCallback(() => setItemHovered(prev => (prev.zone === zone ? {} : prev)), [setItemHovered, zone])

  const canDrop: CanDropFn = useCallback(
    (args: DropTargetGetFeedbackArgs<ElementDragType>) => {
      const { source } = args
      const sourceData = (source?.data ?? {}) as DragSourceData
      let result = true
      if (zone === 'DISPATCH_SIDEBAR' && (!sourceData.dispatchId || !sourceData.vehicleId)) result = false
      else if (zone === 'INSTALLER_SIDEBAR' && (!sourceData.installerId || !sourceData.vehicleDispatchId)) result = false
      else console.error('no matching zone', zone, { ...sourceData })
      return result
    },
    [zone]
  )

  useEffect(() => {
    const dropTargetElement = sidebarRef.current
    if (!dropTargetElement) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: dropTargetElement,
      getData: () => ({ zone }),
      onDragEnter,
      onDragLeave,
      canDrop,
    })
  }, [canDrop, zone, onDragEnter, onDragLeave, sidebarRef])

  return isHovered
}

export default useDropZoneSidebars
