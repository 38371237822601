import useWorkItemAttribute from '../hooks/useWorkItemAttribute'

import { QueriedEstimateWorkItemMaterial } from '../editorTypes'

import NumberCell from './NumberCell'
import TextCell from './TextCell'

const possibleMaterialCostAttributes = ['costPerUnit', 'purchaseContainerUnitQuantity']
const possibleNumberAttributes = ['laborCostPerUnit', 'costPerUnit', 'purchaseContainerUnitQuantity']

type PossibleMaterialCostAttributes = (typeof possibleMaterialCostAttributes)[number]
interface MaterialReadOnlyCellProps {
  workItemId: string | null
  attribute: 'itemCode' | 'name' | 'unitOfMeasure' | 'laborCostPerUnit' | 'costPerUnit' | 'purchaseContainerUnitQuantity'
}

const MaterialReadOnlyCell = ({ workItemId, attribute }: MaterialReadOnlyCellProps) => {
  const material = useWorkItemAttribute('material', workItemId ?? 'null') as QueriedEstimateWorkItemMaterial | null

  let value = null
  let prefix = '$'
  let decimalPlaces = 4
  if (attribute === 'purchaseContainerUnitQuantity') {
    prefix = ''
    decimalPlaces = 2
  }

  if (material) {
    if (possibleMaterialCostAttributes.includes(attribute as PossibleMaterialCostAttributes)) {
      if (material.activeCostSchedule && attribute in material.activeCostSchedule)
        value = material.activeCostSchedule[attribute as keyof typeof material.activeCostSchedule]
    } else if (attribute in material) value = material[attribute as keyof typeof material]
  }

  if (possibleNumberAttributes.includes(attribute))
    return <NumberCell value={value} prefix={prefix} decimalPlaces={decimalPlaces} readOnly />

  return <TextCell value={value} readOnly />
}

export default MaterialReadOnlyCell
