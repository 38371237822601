import { useEffect } from 'react'

import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import type { DragDestinationData, DragSourceData } from '../../dispatchTypes'

import useDragStateMutation from './useDragStateMutation'

interface UseDragDropMonitorProps {
  dateString: string
}

const useDispatchDragDropMonitor = ({ dateString }: UseDragDropMonitorProps) => {
  const { submitAssignment } = useDragStateMutation(dateString)

  useEffect(() => {
    return monitorForElements({
      onDrop: ({ source, location }) => {
        const destination = location.current.dropTargets[0]
        const destinationData = (destination?.data ?? null) as DragDestinationData | null
        if (destinationData === null) return
        const sourceData = (source?.data ?? null) as DragSourceData | null
        if (sourceData === null) return
        submitAssignment()
      },
    })
  }, [submitAssignment])
}

export default useDispatchDragDropMonitor
